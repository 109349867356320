/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import type SearchableDocument from 'embercom/objects/inbox/searchable-document';
import fuzzysort from 'fuzzysort';
import latinize from 'latinize';
import { get } from '@ember/object';

// This is the shape of the sort result coming out of the FuzzySort library
interface FuzzySortLibraryResult<T> {
  score: number;
  obj: T;
  target: string;
}

interface FuzzySortOptions {
  highlight?: boolean;
  threshold?: number;
  limit?: number;
}

export function prepareStringForFuzzySearch(str: string) {
  return fuzzysort.prepare(latinize(str).toLocaleLowerCase());
}

export default class FuzzySearch extends Service {
  // This method latinizes and localizes the search term and also the items being searched upon before performing a search
  // While returning the highlighted value, instead of returning the latinised lower cased item, it returns the original item
  search(
    searchTerm: string,
    items: SearchableDocument[],
    key: string,
    options: FuzzySortOptions = {},
  ): SearchableDocument[] {
    let highlight = options.highlight ?? true;
    let threshold = options.threshold ?? -1000;
    let limit = options.limit ?? Infinity;

    let results = fuzzysort.go(this.toLatinizeLocaleLowerCase(searchTerm), items, {
      threshold,
      key: 'preparedFuzzySearchData',
      limit,
    });

    return results.map((item) =>
      highlight ? item.obj.withHighlight(this.getHighlightedValue(item, key)) : item.obj,
    );
  }

  private getHighlightedValue(item: FuzzySortLibraryResult<SearchableDocument>, key: string) {
    item.target = get(item.obj, key) as string;
    return fuzzysort.highlight(item, '<mark>', '</mark>') || undefined;
  }

  private toLatinizeLocaleLowerCase(value: string) {
    return latinize(value).toLocaleLowerCase();
  }
}

declare module '@ember/service' {
  interface Registry {
    fuzzySearch: FuzzySearch;
    'fuzzy-search': FuzzySearch;
  }
}
