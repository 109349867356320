/* RESPONSIBLE TEAM: team-workflows */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

const TriggerWorkflowChannelCompatibility = BaseValidator.extend({
  intl: service(),

  validate(_stepType, _options, model) {
    let editorConfig = model.editorConfig;
    if (!editorConfig) {
      return true; // Skip this validator until editor config is specified
    }

    let compatibleChannels = model.compatibleChannels;
    let targetChannels = editorConfig.targetChannels;

    if (isEmpty(targetChannels)) {
      return true; // Skip this validator until we can get the target channels
    }

    if (isEmpty(compatibleChannels)) {
      return false; // TODO: Add an error message for this case
    }

    let unsupportedChannels = targetChannels.filter(
      (channel) => !compatibleChannels.includes(channel),
    );

    if (!isEmpty(unsupportedChannels)) {
      return this.intl.t(
        'operator.workflows.visual-builder.validations.trigger-workflow-not-supported-by-channel',
        {
          channels: this.intl.formatList(unsupportedChannels, {
            style: 'long',
            type: 'conjunction',
          }),
        },
      );
    } else {
      return true;
    }
  },
});

TriggerWorkflowChannelCompatibility.reopenClass({
  getDependentsFor() {
    return ['model.compatibleChannels', 'model.editorConfig', 'model.editorConfig.targetChannels'];
  },
});

export default TriggerWorkflowChannelCompatibility;
