/* RESPONSIBLE TEAM: team-tickets-1 */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AudioNotificationService extends Service {
  @tracked audioNotificationsBlocked = false;
  @tracked hideAudioNotificationsBlockedBanner = true;

  setBlockedState(state) {
    this.audioNotificationsBlocked = state;
  }
}
