/* RESPONSIBLE TEAM: team-help-desk-ai */
import Service, { inject as service } from '@ember/service';
import type Admin from 'embercom/models/admin';
import { request } from 'embercom/lib/inbox/requests';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';

export type CopilotWorkspaceUsageResponse = {
  admin_usages: Record<Admin['id'], number>;
};

export const COPILOT_FREE_CREDIT_LIMIT = 10;

export default class CopilotUsageService extends Service {
  @service declare appService: any;

  adminIsAtLimit(admin: Admin) {
    if (admin.hasLimitedCopilotAccess) {
      return (this.adminUsedCreditCounts[admin.id] || 0) >= COPILOT_FREE_CREDIT_LIMIT;
    } else {
      return false;
    }
  }

  get adminUsedCreditCounts(): Record<Admin['id'], number> {
    return this.copilotUsageDataLoader.value?.admin_usages ?? {};
  }

  async refreshCopilotUsageData() {
    if (!this.copilotUsageDataLoader.isFirstLoad) {
      this.copilotUsageDataLoader.reload();
    }
  }

  @use private copilotUsageDataLoader = AsyncData<CopilotWorkspaceUsageResponse>(
    async ({ signal }) => {
      let response = await request(
        `/ember/copilot/workspace_usage?app_id=${this.appService.app.id}`,
        { signal },
      );
      return response.json();
    },
  );
}

declare module '@ember/service' {
  interface Registry {
    CopilotUsageService: CopilotUsageService;
    'copilot-usage-service': CopilotUsageService;
  }
}
