/* RESPONSIBLE TEAM: team-ai-chatbot */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AnswerFollowOnCreationService extends Service {
  @tracked questionsSelected = {};
  @tracked rejectedExampleQuestions = [];

  // Sets and holds the suggested examples questions that were rejected in the questions panel of the edit route.
  setRejectedQuestions(rejectedQuestions) {
    this.rejectedExampleQuestions = rejectedQuestions;
  }

  // Returns whether there are rejected suggested questions that would trigger the
  // 'create new answer from rejected questions' modal.
  get hasRejectedQuestions() {
    return this.rejectedExampleQuestions.length > 0;
  }

  // Sets and holds the questions that have been selected in the 'create new answer from rejected questions' modal
  // to be added to the new answer when transitioning to the new/edit routes.
  setQuestionsSelectedFromRejectedQuestions(questions) {
    this.questionsSelected = questions;
  }

  // Clears the rejected questions and seleted questions once the 'create new answer from rejected questions' flow has
  // been completed or canceled.
  reset() {
    this.rejectedExampleQuestions = [];
    this.questionsSelected = {};
  }
}
