/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-phone */

import Service, { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import { Device, Call } from '@twilio/voice-sdk';
import PhoneCall from 'embercom/objects/phone/phone-call';
import { tracked } from '@glimmer/tracking';
import { isNone } from '@ember/utils';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import Conversation from 'embercom/objects/inbox/conversation';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type InboxApi from 'embercom/services/inbox-api';
import type RouterService from '@ember/routing/router-service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { post } from 'embercom/lib/ajax';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'ember-intl/services/intl';
import { timeout } from 'ember-concurrency';
import type Store from '@ember-data/store';
import type AdminAwayService from 'embercom/services/admin-away-service';
import type LogService from 'embercom/services/log-service';
import moment from 'moment-timezone';
import { type TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';
import { type Notification } from 'embercom/services/snackbar';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type PhoneNumber from 'embercom/models/calling-phone-number';
import ENV from 'embercom/config/environment';
import { cancel, later } from '@ember/runloop';
import { type EmberRunTimer } from 'ember-lifeline/types';
import { ajaxDelete } from 'embercom/lib/ajax';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type User from 'embercom/objects/inbox/user';
import Ember from 'ember';
import Metrics from 'embercom/models/metrics';

const PHONE_NOTIFICATION_ROUTE_PREFIXES = [
  'inbox.workspace.inbox',
  'inbox.workspace.search',
  'inbox.workspace.dashboard',
];

const ONE_MINUTE = ENV.APP._1M;
const PHONE_HELP_DESK_ONLY_NOTIFICATION_DISMISSED = 'phone-help-desk-only-notification-dismissed';
const NOTIFICATION_TIMEOUT = 30000;

export default class TwilioService extends Service {
  @tracked isActiveCall = false;
  @tracked callState: string | null = null;
  @tracked shouldNotifyTeammate = false;
  @tracked conversation?: Conversation;
  @tracked userId?: string;
  @tracked isTransfer = false;
  @tracked oldAdminId: string | undefined = undefined;
  @tracked oldTeamId: string | undefined = undefined;
  @tracked declare isTransferToTeammate: boolean | false;
  @tracked declare isTransferToExternalNumber: boolean | false;
  @service declare snackbar: Snackbar;
  @tracked newTeammateName: string | undefined = undefined;
  @tracked externalNumber?: string;
  @tracked disableHold = false;
  @tracked isRecordingEnabled = false;
  @tracked isRecording = false;
  @tracked isInitialized = false;
  @tracked workspacePhoneNumber: string | undefined = undefined;
  @tracked calledNumberCountryCode: string | undefined = undefined;
  @tracked isListening = false;
  @tracked unregisteredNotification?: Notification;
  @tracked noPermissionNotification?: Notification;
  @tracked hasActivePhoneNumbers = false;
  @tracked isCallback = false;
  @tracked acceptedCallback = true;
  @tracked callbackAcceptanceTimer: NodeJS.Timeout | null = null;
  @tracked adminLacksMicrophonePermissions = false;

  @service declare customerService: any;
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare intercomCallService: IntercomCallService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare adminAwayService: AdminAwayService;
  @service declare logService: LogService;
  @service declare notificationsService: $TSFixMe;

  private pollingTimer?: EmberRunTimer;

  activeCall: PhoneCall | null = null;
  incomingCall: Call | null = null;
  device: Device | null = null;
  callbackAudio: HTMLAudioElement = new window.Audio(assetUrl('/assets/audio/incoming.mp3'));

  get userSummary() {
    if (this.userId) {
      return (
        this.conversation?.participantSummaries.findBy('id', this.userId) ??
        this.conversation?.userSummary
      );
    }

    return this.conversation?.userSummary;
  }

  async initialize() {
    if (this.isInitialized) {
      return;
    }
    let callingSettings = await get(`/ember/inbox/calling_settings`, {
      app_id: this.session.workspace.id,
    });
    this.isRecordingEnabled = callingSettings?.recording_enabled;
    this.isRecording = this.isRecordingEnabled;
    this.hasActivePhoneNumbers =
      callingSettings.phone_numbers.filter((number: PhoneNumber) =>
        ['active', 'missing_bundle'].includes(number.status),
      ).length > 0;

    if (!this.hasActivePhoneNumbers) {
      return; // If there are no active phone numbers, we don't need to initialize the Twilio services
    }

    await this.checkAdminMicrophonePermissions();
    if (this.adminLacksMicrophonePermissions) {
      this.noPermissionNotification = this.snackbar.notify(
        this.intl.t('calling.incoming-phone-call-modal.no-permissions-set'),
        {
          type: 'error',
          persistent: true,
          clearable: true,
        },
      );
    }

    let tokenData = await this.getToken();
    let options: Device.Options = {
      closeProtection: true,
      enableImprovedSignalingErrorPrecision: true,
      tokenRefreshMs: 60000,
    };
    this.device = new Device(tokenData.token, options);
    this.device.on('tokenWillExpire', async () => {
      this.logTwilioEvent('tokenWillExpire', {});
      let newTokenData = await this.getToken();
      if (this.device) {
        this.device.updateToken(newTokenData.token);
      }
    });

    this.device.on('incoming', async (call: Call) => {
      this.logTwilioEvent('call-incoming', call.parameters);
      this.adminAwayService.setAdminAsAvailable();
      this.incomingCall = call;
      this.isTransfer = call.customParameters.get('isTransfer') === 'true';
      this.isCallback = false;
      this.oldAdminId = call.customParameters.get('oldAdminId');
      this.oldTeamId = call.customParameters.get('oldTeamId');
      this.workspacePhoneNumber = `+${call.customParameters.get('workspaceNumber')}`;
      this.calledNumberCountryCode = call.customParameters.get('countryCode');
      this.isListening = call.customParameters.get('listening') === 'true';
      this.conversation = await this.inboxApi.fetchConversation(
        Number(call.customParameters.get('conversationId')),
      );
      this.userId = this.conversation.firstParticipant.id;

      this.shouldNotifyTeammate = true;
      this.recordEvent('receive_phone_call', this.conversation.id);

      this.incomingCall.on('cancel', () => {
        this.activeCall = null;
        this.incomingCall = null;
        this.isActiveCall = false;
        this.shouldNotifyTeammate = false;
        this.isTransferToTeammate = false;
        this.isTransferToExternalNumber = false;
        this.logTwilioEvent('call-cancel', {});
      });

      this.incomingCall.on('disconnect', (call: Call) => {
        this.logTwilioEvent('call-disconnect', call.parameters);
      });

      this.incomingCall.on('accept', (call: Call) => {
        this.logTwilioEvent('call-accept', call.parameters);
      });

      this.incomingCall.on('error', (twilioError: TwilioError) => {
        this.logTwilioEvent('call-error', {}, twilioError);
      });

      this.incomingCall.on('warning', (warningName: string, warningData) => {
        Metrics.capture({
          increment: ['call.quality.warning'],
          tags: {
            warning_name: warningName,
          },
        });
        this.logTwilioEvent('call-warning', { warningName, warningData });
      });

      this.incomingCall.on('reject', () => {
        this.logTwilioEvent('call-reject', {});
      });
    });

    this.device.on('error', async (twilioError: TwilioError) => {
      this.logTwilioEvent('device-error', {}, twilioError);
      if (twilioError.code === 20104 && this.device) {
        let newTokenData = await this.getToken();
        this.device.updateToken(newTokenData.token);
      }
    });

    this.device.on('registered', () => {
      this.startSendingDevicePresence();
      this.logTwilioEvent('device-registered', {});
      if (this.unregisteredNotification) {
        this.snackbar.clearNotification(this.unregisteredNotification);
        this.unregisteredNotification = undefined;
      }
      if (this.hasActivePhoneNumbers) {
        this.snackbar.notify(this.intl.t('calling.incoming-phone-call-modal.ready-to-receive'), {
          persistent: false,
          clearable: true,
        });
      }
    });

    this.device.on('unregistered', () => {
      this.stopSendingDevicePresence();
      this.logTwilioEvent('device-unregistered', {});
      if (this.hasActivePhoneNumbers) {
        if (
          PHONE_NOTIFICATION_ROUTE_PREFIXES.some((routePrefix) =>
            this.router.currentRouteName?.startsWith(routePrefix),
          )
        ) {
          try {
            if (!this.unregisteredNotification) {
              this.unregisteredNotification = this.snackbar.notify(
                this.intl.t('calling.incoming-phone-call-modal.unregistered'),
                {
                  type: 'error',
                  persistent: true,
                  clearable: true,
                  contentComponent: 'inbox2/left-nav/notification-nexus-error',
                },
              );
            }
            // Following best practices, we attempt to re-register the device
            // https://www.twilio.com/docs/voice/sdks/javascript/best-practices#device-is-not-available-for-calls-onunregistered-handler
            this.device?.register();
          } catch (e) {
            this.logTwilioEvent('device-re-registration-failed', {}, e);
          }
        } else if (localStorage.getItem(PHONE_HELP_DESK_ONLY_NOTIFICATION_DISMISSED) !== 'true') {
          // Teammate has moved away from the help desk
          this.notificationsService.notifyWarning(
            this.intl.t('calling.incoming-phone-call-modal.unregistered-away-from-help-desk', {
              url: '#',
              htmlSafe: true,
            }),
            NOTIFICATION_TIMEOUT,
          );
          localStorage.setItem(PHONE_HELP_DESK_ONLY_NOTIFICATION_DISMISSED, 'true');
        }
      }
    });

    this.device.on('destroyed', () => {
      this.logTwilioEvent('device-destroyed', {});
    });

    this.device.register();
    this.isInitialized = true;
  }

  async unregisterDevice() {
    if (this.isInitialized) {
      this.device?.destroy();
      this.isInitialized = false;
    }
  }

  async acceptListeningCall(conversationId?: number | undefined) {
    this.shouldNotifyTeammate = false;
    if (conversationId && !this.conversation) {
      this.conversation = await this.inboxApi.fetchConversation(conversationId);
    }
    if (!this.conversation || !this.incomingCall) {
      return;
    }
    this.incomingCall.accept();
    this.intercomCallService.setAdminAsCoaching();
    this.activeCall = new PhoneCall(this.incomingCall, this.conversation);
    this.isActiveCall = true;
    this.recordEvent('start_listening_to_call', this.conversation.id);

    this.activeCall.onStateChange(async (event: CustomEvent) => {
      this.callState = event.detail.type;

      if (event.detail.type === 'disconnect') {
        if (this.conversation) {
          this.recordEvent('end_listening_to_call', this.conversation.id);
        }

        this.activeCall = null;
        this.incomingCall = null;
        this.isActiveCall = false;

        this.adminAwayService.setAdminAsAvailable();
        this.isListening = false;
        this.shouldNotifyTeammate = false;
      }
    });
  }

  async acceptCall() {
    this.shouldNotifyTeammate = false;
    if (!this.conversation || !this.incomingCall) {
      return;
    }
    if (this.isTransfer) {
      await this.removeAdminFromCall();
    }
    this.incomingCall.accept();
    this.intercomCallService.setAdminOnCall();
    this.activeCall = new PhoneCall(this.incomingCall, this.conversation);
    this.isActiveCall = true;
    this.recordEvent('accept_inbound_phone_call', this.conversation.id);

    this.activeCall.onStateChange(async (event: CustomEvent) => {
      this.callState = event.detail.type;
      let callConversation = this.activeCall?.conversation;
      let hangedUpByAdmin = this.activeCall?.hangedUpByAdmin;

      if (event.detail.type === 'disconnect') {
        let duration = this.activeCall?.duration;
        if (this.conversation) {
          this.recordEvent('end_inbound_phone_call', this.conversation.id, duration);
        }

        this.activeCall = null;
        this.incomingCall = null;
        this.isActiveCall = false;

        if (event.detail.oldType === 'accept' && callConversation instanceof Conversation) {
          await this.intercomCallService.setEndCallAdminState();
        }
        if (this.isTransferToTeammate && !hangedUpByAdmin) {
          this.snackbar.notify(
            this.intl.t('calling.incoming-phone-call-modal.successful-transfer', {
              name: this.newTeammateName,
            }),
          );
        } else if (this.isTransferToExternalNumber && !hangedUpByAdmin) {
          this.snackbar.notify(
            this.intl.t('calling.incoming-phone-call-modal.successful-transfer', {
              name: this.externalNumber,
            }),
          );
        }
        this.isTransferToTeammate = false;
        this.isTransferToExternalNumber = false;
      }
    });

    this.navigateToConversation();
  }

  async rejectCall() {
    if (!this.incomingCall) {
      return;
    }

    this.shouldNotifyTeammate = false;
    this.incomingCall.reject();
    this.resetCallAttributes();
  }

  async callNumber(
    phoneNumber: string,
    conversation: Conversation,
    user: User | UserSummary,
    workspaceNumber: string | null = null,
  ) {
    if (!this.isInitialized) {
      await this.initialize();
    }

    this.intercomCallService.setAdminOnCall();

    this.conversation = conversation;
    this.userId = user.id.toString();

    let outgoingCallParams: any = {
      To: phoneNumber,
      AppId: this.session.workspace.id,
      ConversationId: conversation.id.toString(),
      AdminId: this.session.teammate.id.toString(),
      UserId: this.userId,
    };

    outgoingCallParams = {
      ...outgoingCallParams,
      WorkspacePhoneNumber: workspaceNumber,
    };

    let outgoingCall = await this.device?.connect({
      params: outgoingCallParams,
    });

    if (!(outgoingCall instanceof Call)) {
      return null;
    }

    this.activeCall = new PhoneCall(outgoingCall, conversation);
    this.recordEvent('initiate_phone_call', conversation.id);

    this.isActiveCall = true;
    this.workspacePhoneNumber = workspaceNumber || undefined;

    this.activeCall.onStateChange(async (event: CustomEvent) => {
      this.callState = event.detail.type;
      let callConversation = this.activeCall?.conversation;

      if (event.detail.type === 'disconnect') {
        let duration = this.activeCall?.duration;
        let hangedUpByAdmin = this.activeCall?.hangedUpByAdmin;
        this.recordEvent('end_phone_call', conversation.id, duration);

        this.resetCallAttributes();

        if (event.detail.oldType === 'accept' && callConversation instanceof Conversation) {
          await this.intercomCallService.setEndCallAdminState();
        }
        if (
          ['ringing', 'error'].includes(event.detail.oldType) &&
          callConversation instanceof Conversation
        ) {
          if (hangedUpByAdmin) {
            this.adminAwayService.setAdminAsAvailable();
          } else {
            await this.intercomCallService.endCall(callConversation.id, duration);
          }
        }
      } else if (event.detail.type === 'accept' && callConversation instanceof Conversation) {
        await this.intercomCallService.startEscalationCall(
          callConversation.id,
          outgoingCall?.parameters.CallSid,
        );

        this.recordEvent('phone_call_accepted', conversation.id);
      }
    });

    return this.activeCall;
  }

  async toggleOnHold(isOnHold: boolean) {
    if (!this.conversation) {
      return;
    }

    let url = isOnHold ? '/ember/phone_call/take_off_hold' : '/ember/phone_call/place_on_hold';
    await post(url, {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
  }

  async toggleRecording() {
    if (!this.conversation) {
      return;
    }

    if (this.isRecording) {
      await this.intercomCallService.stopRecording(this.conversation.id);
      this.isRecording = false;
    } else {
      await this.intercomCallService.startRecording(this.conversation.id);
      this.isRecording = true;
    }
  }

  async transferToTeam(teamId: number) {
    if (!this.conversation || !this.incomingCall || !this.isActiveCall) {
      return;
    }

    await post('/ember/phone_call/transfer_to_team', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
      team_id: teamId,
    });
  }

  async transferToAdmin(adminId: number) {
    if (!this.conversation || !this.isActiveCall) {
      return;
    }
    try {
      this.isTransferToTeammate = true;
      this.disableHold = true;
      await post('/ember/phone_call/transfer_to_admin', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        admin_id: adminId,
      });
      await timeout(10000);
      this.disableHold = false;
    } catch (e) {
      this.isTransferToTeammate = false;
      this.disableHold = false;
      throw e;
    }
  }

  async transferToExternalNumber(externalNumber: string) {
    if (!this.conversation || !this.isActiveCall) {
      return;
    }

    try {
      this.isTransferToExternalNumber = true;
      this.disableHold = true;
      await post('/ember/phone_call/transfer_to_external_number', {
        app_id: this.session.workspace.id,
        conversation_id: this.conversation.id,
        external_number: externalNumber,
      });
      if (!Ember.testing) {
        await timeout(10000);
      }
      this.disableHold = false;
    } catch (e) {
      this.isTransferToExternalNumber = false;
      this.disableHold = false;
      throw e;
    }
  }

  async notifyInboundCallback(event: any) {
    this.callbackAudio.loop = true;
    this.callbackAudio.play();
    this.isCallback = true;
    this.acceptedCallback = false;
    this.calledNumberCountryCode = event.eventData.countryCode;
    this.conversation = await this.inboxApi.fetchConversation(event.eventData.conversationId);
    this.userId = this.conversation.firstParticipant.id;
    this.shouldNotifyTeammate = true;
    this.callbackAcceptanceTimer = setTimeout(() => {
      if (!this.acceptedCallback) {
        this.ignoreCallback();
        this.conversation = undefined;
        this.userId = undefined;
      }
    }, NOTIFICATION_TIMEOUT);
  }

  async teammateHangUp() {
    if (!this.conversation || !this.incomingCall) {
      return;
    }

    await post('/ember/phone_call/teammate_hangup', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
  }

  async removeAdminFromCall() {
    if (!this.conversation || !this.incomingCall) {
      return;
    }

    await post('/ember/phone_call/remove_admin_from_call', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation.id,
    });
  }

  tearDownCallbackModal() {
    this.shouldNotifyTeammate = false;
    this.callbackAudio.pause();
    this.callbackAudio.currentTime = 0;
  }

  async acceptCallback(workspaceNumber: string | undefined, conversationId?: number | undefined) {
    if (conversationId) {
      this.conversation = await this.inboxApi.fetchConversation(conversationId);
      this.userId = this.conversation?.firstParticipant.id;
    }
    if (!this.conversation || !workspaceNumber) {
      return;
    }
    this.tearDownCallbackIgnoreTimer();
    let number = this.conversation.user?.phone as string;
    this.tearDownCallbackModal();
    await this.callNumber(number, this.conversation, this.userSummary!, workspaceNumber);
    this.acceptedCallback = true;
  }

  async closeCallback(conversationId: number) {
    await post('/ember/phone_call/close_callback', {
      app_id: this.session.workspace.id,
      conversation_id: conversationId,
    });
  }

  async ignoreCallback() {
    this.tearDownCallbackIgnoreTimer();
    this.tearDownCallbackModal();
    await post('/ember/phone_call/ignore_callback', {
      app_id: this.session.workspace.id,
      conversation_id: this.conversation!.id,
    });
  }

  async checkAdminMicrophonePermissions() {
    try {
      // Mozilla does not support querying generically for microphone permissions. Instead Firefox has an expectation that
      // an end user should have more granular control over which device is accessed https://github.com/mozilla/standards-positions/issues/19
      // Therefor if this block of code throws we should just skip the check for now.
      let microphonePermission = 'microphone' as PermissionName;
      let permission = await navigator.permissions.query({ name: microphonePermission });
      this.adminLacksMicrophonePermissions = permission.state === 'denied';
      this.handlePermissionChangedEvent(permission);
    } catch (error) {
      this.logTwilioEvent('permissions-exception', {});
    }
  }

  handlePermissionChangedEvent(permission: PermissionStatus) {
    permission.onchange = ({ target }: Event) => {
      let updatedPermission = target as PermissionStatus;
      this.adminLacksMicrophonePermissions = updatedPermission.state !== 'granted';

      if (this.noPermissionNotification) {
        this.snackbar.clearNotification(this.noPermissionNotification);
      }
    };
  }

  navigateToConversation() {
    if (!this.conversation) {
      return;
    }

    let inbox = this.inboxState.activeInbox || {
      id: InboxType.All,
      category: InboxCategory.Shared,
    };

    this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      inbox.category,
      inbox.id,
      this.conversation.id,
    );
  }

  async recordEvent(action: string, conversationId: number, duration?: number | null) {
    let customer = this.customerService.customer;

    let payload: any = {
      action,
      object: 'conversation',
      place: 'inbox2',
      conversation_id: conversationId,
      is_trial: customer?.hasActiveTrials,
    };

    if (!isNone(duration)) {
      payload.duration = duration;
    }

    this.intercomEventService.trackAnalyticsEvent(payload);
  }

  tearDownCallbackIgnoreTimer() {
    if (this.callbackAcceptanceTimer !== null) {
      clearInterval(this.callbackAcceptanceTimer);
    }
  }

  resetCallAttributes() {
    this.conversation = undefined;
    this.userId = undefined;
    this.activeCall = null;
    this.isActiveCall = false;
    this.isTransferToTeammate = false;
    this.isTransferToExternalNumber = false;
  }

  getToken() {
    return get('/ember/twilio/token', {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
    });
  }

  logTwilioEvent(eventType: string, eventData: any, error?: TwilioError) {
    this.logService.logJSON({
      timestamp: moment().format(),
      event: 'twilio_event',
      event_type: eventType,
      event_data: eventData,
      error,
      errorCode: error?.code,
    });
  }

  get isCallRinging() {
    return this.callState === 'connecting' || this.callState === 'ringing';
  }

  get isCallEnded() {
    return this.callState === 'closed';
  }

  startSendingDevicePresence() {
    if (this.session.workspace.isFeatureEnabled('phone-twilio-device-health-check')) {
      this.sendDevicePresencePeriodically();
    }
  }

  stopSendingDevicePresence() {
    if (this.session.workspace.isFeatureEnabled('phone-twilio-device-health-check')) {
      this.pollingTimer && cancel(this.pollingTimer);
      this.destroyDevicePresence();
    }
  }

  sendDevicePresencePeriodically() {
    if (this.device && this.device.state === 'registered') {
      this.sendDevicePresence();
    }
    this.pollingTimer = later(this, () => this.sendDevicePresencePeriodically(), ONE_MINUTE);
  }

  sendDevicePresence() {
    post('/ember/twilio_device_presence', {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
    }).catch((err: any) => {
      this.logTwilioEvent('heartbeat-error', {}, err);
      throw err;
    });
  }

  destroyDevicePresence() {
    ajaxDelete('/ember/twilio_device_presence', {
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
    }).catch((err: any) => {
      this.logTwilioEvent('heartbeat-error', {}, err);
      throw err;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    twilioService: TwilioService;
    'twilio-service': TwilioService;
  }
}
