/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service, { inject as service } from '@ember/service';
import type Session from './session';
import { get, put } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { type NexusEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName, NexusStatusEvents } from 'embercom/services/nexus';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import storage from 'embercom/vendor/intercom/storage';
import type Inbox2AssigneeSearch from './inbox2-assignee-search';
import { type ChannelAvailabilityCode } from './inbox2-teammate-activity-service';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';

interface AwaySettingsWireFormat {
  away_status_reason: string;
  away_mode_enabled: boolean;
  reassign_conversations: boolean;
  default_assignee_id: number;
  is_auto_changed: boolean;
  auto_changed_time: any;
  channel_availability: string;
}

enum SupportedOperations {
  ToggleAwayMode,
  TogglereassignConversations,
  SetAwayReason,
  SetAvailableChannel,
  SetAvailableChannelForTeammate,
}

export const AWAY_REASONS = [
  '🧑‍🏫 Coaching',
  '😌 On a break',
  '🍔 On lunch',
  '🗓️ In a meeting',
  '📞 On a call',
  '📝 Call wrap up',
  '🏡 Done for the day',
  '🌴 On holidays',
  '🤒 Out sick',
];
export type AwayReason = (typeof AWAY_REASONS)[number] | null;

export const HAS_CHANGED_OWN_STATUS_KEY = 'has_changed_own_away_status';
export const SILENCE_AWAY_WARNING_KEY = 'silence_away_mode_warning';

export default class AdminAwayService extends Service {
  @service declare session: Session;
  @service declare nexus: Nexus;
  @service declare intl: IntlService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare trackedLocalStorage: any;
  @service declare lbaMetricsService: LbaMetricsService;

  @tracked awayStatusReason?: AwayReason;
  @tracked awayModeEnabled?: boolean;
  @tracked reassignConversations?: boolean;
  @tracked defaultAssigneeId?: number;
  @tracked isAutoChanged?: boolean;
  @tracked autoChangedTime: any;
  @tracked displayModalPrompt = false;
  @tracked channelAvailability = 'conversations';

  private nexusListener?: (e: NexusEvent) => void;

  constructor() {
    super(...arguments);
    this.subscribe();
    this.getAwaySettings();
    this.nexus.on(NexusStatusEvents.Reconnected, () => this.getAwaySettings());
  }

  subscribe() {
    this.nexusListener = (e: NexusEvent) => {
      this.handleAdminStatusChange(e);
    };
    this.nexus.addListener(NexusEventName.AdminAwayReassignModeChangeEvent, this.nexusListener);
  }

  toggleAwayMode() {
    this.awayModeEnabled = !this.awayModeEnabled;
    this.reassignConversations = false;
    this.awayStatusReason = null;
    this.isAutoChanged = false;
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, this.currentTeammateContext);
  }

  setAdminAsAway(awayStatusReason: AwayReason = null) {
    this.awayModeEnabled = true;
    this.reassignConversations = false;
    this.awayStatusReason = awayStatusReason;
    this.isAutoChanged = false;
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, this.currentTeammateContext);
  }

  setAdminAsAvailable() {
    this.awayModeEnabled = false;
    this.reassignConversations = false;
    this.awayStatusReason = null;
    this.isAutoChanged = false;
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, this.currentTeammateContext);
  }

  toggleAwayModeFor(teammate_id: number, away_mode_enabled: boolean) {
    let context = {
      app_id: this.session.workspace.id,
      id: teammate_id,
      reassign_conversations: false,
      away_status_reason: null,
      away_mode_enabled,
    };
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, context);
  }

  toggleReassignConversations() {
    this.reassignConversations = !this.reassignConversations;
    this.isAutoChanged = false;
    this.setAwaySettings(
      SupportedOperations.TogglereassignConversations,
      this.currentTeammateContext,
    );
  }

  toggleReassignConversationsFor(teammate_id: number, reassign: boolean) {
    let context = {
      app_id: this.session.workspace.id,
      id: teammate_id,
      reassign_conversations: reassign,
      away_mode_enabled: true,
    };
    this.setAwaySettings(SupportedOperations.ToggleAwayMode, context);
  }

  setAwayReason(reason: AwayReason) {
    this.awayStatusReason = reason;
    this.intercomEventService.trackAnalyticsEvent({
      action: `Away status reason changed to ${reason}`,
      object: 'away_status_reason_setting',
      context: 'from_profile',
    });
    this.setAwaySettings(SupportedOperations.SetAwayReason, this.currentTeammateContext);
  }

  // There are issues where ember misses admin away status changes triggered from the backend during call wrap up
  // This method is used to force backend consistency in cases where wrap up's away status should have been cleared but was not
  async forceAdminConsistency(wrapUpTimeAmount: number) {
    this.getAwaySettings();

    let consistencyBuffer = 5_000;
    let wrapUpDurationMs = wrapUpTimeAmount * 1_000 + consistencyBuffer;

    if (!this.awayModeEnabled) {
      return;
    }

    let intervalledReload = setInterval(() => {
      this.getAwaySettings();
    }, consistencyBuffer);

    setTimeout(() => {
      clearInterval(intervalledReload);
    }, wrapUpDurationMs);
  }

  async toggleAvailableChannel(value: string) {
    this.channelAvailability = value;
    await this.setAvailableChannel(
      SupportedOperations.SetAvailableChannel,
      this.currentAvailableContext,
    );
  }

  async toggleAvailabeChannelForTeammate(adminId: number, value: ChannelAvailabilityCode) {
    if (adminId === this.session.teammate.id) {
      return this.toggleAvailableChannel(value);
    }

    let context = {
      app_id: this.session.workspace.id,
      id: adminId,
      channel_availability: value,
    };
    await this.setAvailableChannelForTeammate(context);
  }

  async getAwaySettings() {
    let data: AwaySettingsWireFormat = await get(
      `/ember/admins/away_settings.json?app_id=${this.session.workspace.id}`,
    );
    this.awayStatusReason = !data.away_status_reason
      ? null
      : (data.away_status_reason as AwayReason);
    this.awayModeEnabled = data.away_mode_enabled;
    this.defaultAssigneeId = data.default_assignee_id;
    this.reassignConversations = data.reassign_conversations;
    this.isAutoChanged = data.is_auto_changed;
    this.autoChangedTime = data.auto_changed_time;
    this.channelAvailability = data.channel_availability;
  }

  async setAwaySettings(kind: SupportedOperations, context: any = {}) {
    try {
      if (context.id === this.session.teammate.id) {
        this.trackTeammateComingBackOnline(kind, context);
        this.trackedLocalStorage.setItem(HAS_CHANGED_OWN_STATUS_KEY, '1');
        await put(`/ember/admins/change_own_away_mode`, context);
      } else {
        await put(`/ember/admins/change_away_mode`, context);
      }
    } catch (e) {
      this.revertCurrentTeammateStatus(kind, context);
      this.notificationsService.notifyError(this.intl.t('inbox.user-menu.set-status-error'));
      throw e;
    }
  }

  async setAvailableChannel(kind: SupportedOperations, context: any = {}) {
    try {
      await put(`/ember/admins/change_active_channel`, context);
    } catch (e) {
      this.revertCurrentTeammateStatus(kind, context);
      this.notificationsService.notifyError(this.intl.t('inbox.user-menu.set-status-error'));
      throw e;
    }
  }

  async setAvailableChannelForTeammate(context: {
    app_id: string;
    id: number;
    channel_availability: ChannelAvailabilityCode;
  }) {
    try {
      await put(`/ember/admins/change_teammate_active_channel`, context);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.dashboard.teammate-activity.table.set-status-error'),
      );
      throw e;
    }
  }

  getTranslationKeyForAwayReason(reason: AwayReason) {
    let map: { [reason: string]: string } = {
      '🧑‍🏫 Coaching': 'coaching',
      '😌 On a break': 'on-a-break',
      '🍔 On lunch': 'on-lunch',
      '🗓️ In a meeting': 'in-a-meeting',
      '📞 On a call': 'on-a-call',
      '📝 Call wrap up': 'call-wrap-up',
      '🏡 Done for the day': 'done-for-the-day',
      '🌴 On holidays': 'on-holidays',
      '🤒 Out sick': 'out-sick',
    };
    if (!reason) {
      return 'default';
    }
    return map[reason];
  }

  handleAdminStatusChange(e: NexusEvent) {
    if (e.eventData.admin_id !== this.session.teammate.id) {
      let admin = this.inbox2AssigneeSearch.findAdminById(e.eventData.admin_id);
      if (admin) {
        admin.adminStatus = {
          awayModeEnabled: e.eventData.away_mode_enabled,
          reassignConversations: e.eventData.reassign_conversations,
          isAutoChanged: e.eventData.is_auto_changed,
          channelAvailability: e.eventData.channel_availability,
        };
      }
      return;
    }

    this.awayModeEnabled = e.eventData.away_mode_enabled;
    this.reassignConversations = e.eventData.reassign_conversations;
  }

  @action maybeDisplayModalPrompt() {
    if (storage.get(SILENCE_AWAY_WARNING_KEY)) {
      return;
    }

    if (!this.awayModeEnabled) {
      return;
    }

    this.displayModalPrompt = true;
  }

  @action silenceAwayModePrompt() {
    storage.set(SILENCE_AWAY_WARNING_KEY, true);
  }

  private revertCurrentTeammateStatus(kind: SupportedOperations, context: any = {}) {
    if (context.id === this.session.teammate.id) {
      switch (kind) {
        case SupportedOperations.ToggleAwayMode: {
          this.awayModeEnabled = !this.awayModeEnabled;
          break;
        }
        case SupportedOperations.TogglereassignConversations: {
          this.reassignConversations = !this.reassignConversations;
          break;
        }
        case SupportedOperations.SetAvailableChannel: {
          this.channelAvailability = 'conversation';
          break;
        }
      }
    }
  }

  private get currentAvailableContext() {
    return {
      app_id: this.session.workspace.id,
      id: this.session.teammate.id,
      channel_availability: this.channelAvailability,
    };
  }

  private get currentTeammateContext() {
    return {
      app_id: this.session.workspace.id,
      id: this.session.teammate.id,
      reassign_conversations: this.reassignConversations,
      away_mode_enabled: this.awayModeEnabled,
      away_status_reason: this.awayStatusReason,
    };
  }

  private trackTeammateComingBackOnline(
    kind: SupportedOperations,
    context: { away_mode_enabled: any },
  ) {
    if (kind === SupportedOperations.ToggleAwayMode && !context.away_mode_enabled) {
      this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(LbaTriggerEvent.ONLINE);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    adminAwayService: AdminAwayService;
    'admin-away-service': AdminAwayService;
  }
}
