/* RESPONSIBLE TEAM: team-tickets-1 */
import { cancel, later, run } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import { postRequest } from '../lib/inbox/requests';
import storage from 'embercom/vendor/intercom/storage';
import { isEmpty } from '@ember/utils';

const LOCAL_STORAGE_KEY = 'admin-ping-service.lastAdminPingTimestamp';
const ONE_MINUTE = ENV.APP._1M;
const FOUR_MINUTES = ONE_MINUTE * 4;

export default class AdminPingService extends Service {
  @service logService;

  constructor() {
    super();

    this._onVisibilityChange = this._onVisibilityChange.bind(this);
    this._onTrackUserActivity = this._onTrackUserActivity.bind(this);
  }

  get isPollingStarted() {
    return !isEmpty(this.pollingTimer);
  }

  start(appId, adminId, canUseTeammateStatusAutoAwayMode) {
    if (ENV.environment === 'test' && !this.forceTesting) {
      return;
    }

    document.addEventListener('visibilitychange', this._onVisibilityChange);
    document.addEventListener('pointermove', this._onTrackUserActivity);
    document.addEventListener('pointerdown', this._onTrackUserActivity);
    document.addEventListener('pointerup', this._onTrackUserActivity);
    document.addEventListener('keydown', this._onTrackUserActivity);
    document.addEventListener('keyup', this._onTrackUserActivity);

    this.userHasActivity = false;
    this.appId = appId;
    this.adminId = adminId;

    if (canUseTeammateStatusAutoAwayMode) {
      this.interval = ONE_MINUTE;
    } else {
      this.interval = FOUR_MINUTES;
    }

    this._pingPeriodically();
  }

  stop() {
    cancel(this.pollingTimer);

    document.removeEventListener('visibilitychange', this._onVisibilityChange);
    document.removeEventListener('pointermove', this._onTrackUserActivity);
    document.removeEventListener('pointerdown', this._onTrackUserActivity);
    document.removeEventListener('pointerup', this._onTrackUserActivity);
    document.removeEventListener('keydown', this._onTrackUserActivity);
    document.removeEventListener('keyup', this._onTrackUserActivity);

    this.userHasActivity = false;
    this.pollingTimer = null;
    this.appId = null;
    this.adminId = null;
  }

  pause() {
    // we don't want to null out app and admin when we pause.
    cancel(this.pollingTimer);
    this.pollingTimer = null;
  }

  _pingPeriodically() {
    if (this.userHasActivity) {
      this._ping();
    }

    this.userHasActivity = false;
    this.pollingTimer = this._defer(this, this._pingPeriodically, ONE_MINUTE);
  }

  _hasPingedRecently() {
    let lastAdminPingTimestamp = storage.get(this._storageKey);

    let hasPingedRecently =
      lastAdminPingTimestamp && Date.now() - lastAdminPingTimestamp < this.interval;

    if (hasPingedRecently) {
      return true;
    } else {
      storage.set(this._storageKey, Date.now());
      return false;
    }
  }

  _onVisibilityChange() {
    if (this.isPollingStarted && document.hidden) {
      this.pause();
    } else if (!this.isPollingStarted && !document.hidden) {
      this._pingPeriodically();
    }
  }

  _onTrackUserActivity() {
    this.userHasActivity = true;
  }

  get _storageKey() {
    return `${LOCAL_STORAGE_KEY}-${this.appId}-${this.adminId}`;
  }

  // method is here so we can mock it for integration tests and wait for andThen
  _defer() {
    return later.apply(run, arguments);
  }

  _ping() {
    if (this._hasPingedRecently()) {
      return;
    }

    postRequest('/ember/ping.json', { app_id: this.appId }, { priority: 'low' }).catch((e) => {
      if (e.response?.status === 403) {
        this.stop();
      }
      this.logService.log(e);
    });
  }
}
