/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  brandName: [
    validator('presence', {
      presence: true,
      message: 'Add a brand name',
    }),
    validator('inline', {
      validate: (value, options, model, attribute) => {
        let result = !model.otherBrands.any((brand) => {
          return brand.brandName === model.brandName;
        });

        return result || 'Choose a brand name that you’re not already using';
      },
    }),
  ],
  mobileBundleId: [
    validator('inline', {
      validate: (value, options, model, attribute) => {
        if (!model.isMobile) {
          return true;
        }

        if (!model.mobileBundleId) {
          return 'Please enter your Mobile app ID';
        }

        let result = !model.otherBrands.any((brand) => {
          return brand.mobileBundleId === model.mobileBundleId;
        });

        return (
          result ||
          'You have already associated that App ID with a brand. Please enter a different Mobile App ID.'
        );
      },
    }),
  ],
  brandDomains: [
    validator('inline', {
      validate: (value, options, model, attribute) => {
        if (model.isMobile) {
          return true;
        }

        if (model.isDefault) {
          return true;
        }

        if (model.allDomainsEmpty) {
          return 'Add at least one domain';
        }

        let message = 'All domains must be valid';
        if (model.brandDomains.length === 1) {
          return model.brandDomains.firstObject.isValid || message;
        }
        return (
          (!model.brandDomains.any((domain) => {
            return !domain.isValid && !domain.isEmpty;
          }) &&
            model.brandDomains.length > 0) ||
          message
        );
      },
      dependentKeys: ['model.brandDomains.@each.domain'],
    }),
  ],
});
