/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask, enqueueTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import type Store from '@ember-data/store';
import type Report from 'embercom/models/reporting/custom/report';
import ReportAccessRequestModal from 'embercom/components/reporting/custom/report-access-request-modal';

export enum AccessType {
  RESTRICTED_VIEW = 'restricted_view',
  VIEW = 'view',
  EDIT = 'edit',
}

export default class ReportAccessService extends Service {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare permissionsService: any;
  @service declare modalService: any;
  @service declare router: any;
  @service declare intercomEventService: any;

  @tracked adminAccess: ReportAccessControlList[] = [];
  @tracked reportAccess: ReportAccessControlList[] = [];

  constructor() {
    super(...arguments);
  }

  get app() {
    return this.appService.app;
  }

  //task to get access from then backend for the current user
  async loadAdminReportAccess() {
    return taskFor(this.fetchAdminReportAccess).perform();
  }

  get loadAdminReportAccessIsRunning() {
    return taskFor(this.fetchAdminReportAccess).isRunning;
  }

  @enqueueTask
  *fetchAdminReportAccess(): TaskGenerator<void> {
    // using query here to ensure that when we load model
    // if teammate on another browser has deleted access control
    //  we do not return them from store.
    // we only return exact response from backend. not backendResponse + dataInStore
    // reason here https://github.com/intercom/intercom/issues/354091#issuecomment-2337524088
    this.adminAccess = yield this.store.query('reporting/report_access_control_list', {});
  }

  //task to get access from then backend for a specific report
  async loadReportAccess(reportId: string) {
    return taskFor(this.fetchReportAccess).perform(reportId);
  }

  @dropTask
  *fetchReportAccess(reportId: string): TaskGenerator<void> {
    let results = yield this.store.query('reporting/report-access-control-list', {
      report_id: reportId,
    });
    this.reportAccess = results;
    return results;
  }

  // current report access for an admin
  currentAdminReportAccess(reportId: string): ReportAccessControlList | undefined {
    return this.adminAccess.find((access) => {
      return access.reportId === reportId && access.adminId === this.appService.app.currentAdmin.id;
    });
  }

  currentReportWorkspaceAccess(reportId: string): ReportAccessControlList | undefined {
    return this.adminAccess.find((control) => {
      return control.adminId === null && control.reportId === reportId;
    });
  }

  // all exisiting access on a report
  currentReportAccess() {
    return this.reportAccess;
  }

  private get sharedAnalyticsData() {
    return {
      object: 'report_access',
      section: 'reports',
    };
  }

  // create access for a report
  @action
  async createAccess(reportId: string, accessType: AccessType, adminId?: number) {
    let access = this.store.createRecord('reporting/report-access-control-list', {
      reportId,
      accessType,
      adminId,
    });
    await access.save();
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'created',
      report_id: reportId,
      is_workspace: adminId === null,
      access_type: accessType,
    });
  }

  async removeAccess(control: ReportAccessControlList) {
    control.destroyRecord();
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'delete',
      report_id: control.reportId,
      is_workspace: control.adminId === null,
      access_type: control.accessType,
    });
  }

  sendInvites(reportId: string, accessType: AccessType, adminIds: string[]) {
    ajax({
      url: '/ember/reporting/report_access_control_list/bulk_create',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        admin_ids: adminIds,
        report_id: reportId,
        access_type: accessType,
      }),
    });
    adminIds.forEach((adminId) => {
      this.intercomEventService.trackAnalyticsEvent({
        ...this.sharedAnalyticsData,
        action: 'created',
        report_id: reportId,
        is_workspace: adminId === null,
        access_type: accessType,
      });
    });
  }

  // update access
  @enqueueTask
  *updateTeammateAccessType(report: Report, adminId: string, accessType: AccessType) {
    let accessControlList: ReportAccessControlList[] = yield taskFor(
      this.fetchReportAccess,
    ).perform(report.id);

    let control = accessControlList.find((control) => control.adminId === adminId);
    if (control) {
      yield this.updateAccess(control, accessType);
    }
  }

  async updateAccess(control: ReportAccessControlList, accessType: AccessType) {
    let previousAccessType = control.accessType;
    control.updateAccess(accessType);
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'changed',
      report_id: control.reportId,
      is_workspace: control.adminId === null,
      from: previousAccessType,
      to: accessType,
    });
  }

  @enqueueTask
  *updateOrCreateWorkSpaceAccess(report: Report, accessType: AccessType | null) {
    let accessControlList: ReportAccessControlList[] = yield taskFor(
      this.fetchReportAccess,
    ).perform(report.id);

    let control = accessControlList.find((control) => control.adminId === null);

    if (control && accessType) {
      //if we have workspace control and a new access type -> update it
      yield this.updateAccess(control, accessType);
    } else if (!control && accessType) {
      //if we have do not have workspace control -> create it with provided type
      yield this.createAccess(report.id, accessType, undefined);
    } else if (control && !accessType) {
      //if we have workspace control but no access type provided -> we delete it (user selected no access)
      yield this.removeAccess(control);
    }
  }

  adminHasEditAccess(report: Report) {
    return (
      report.createdById === this.appService.app.currentAdmin.id ||
      this._highestAccessLevel(report) === AccessType.EDIT
    );
  }

  adminHasViewAccess(report: Report) {
    let access = this._highestAccessLevel(report);

    return access === AccessType.VIEW || access === AccessType.EDIT;
  }

  adminHasRestrictedAccess(report: Report) {
    return this._highestAccessLevel(report) === AccessType.RESTRICTED_VIEW;
  }

  adminHasAccess(report: any) {
    return (
      (this.currentAdminReportAccess(report?.id) ||
        report?.createdById === this.appService.app.currentAdmin.id) &&
      report !== null
    );
  }

  loadReportAccessModal(reportId: string, onClose: () => void) {
    this.modalService.openModal(ReportAccessRequestModal, {
      reportId,
      onClose,
    });
  }

  _highestAccessLevel(report: Report) {
    let access = this.currentAdminReportAccess(report.id);
    let workspaceAccess = this.currentReportWorkspaceAccess(report.id);

    if (!access && !workspaceAccess) {
      return undefined;
    }

    if (
      access?.accessType === AccessType.EDIT ||
      workspaceAccess?.accessType === AccessType.EDIT ||
      report.createdById === this.appService.app.currentAdmin.id
    ) {
      return AccessType.EDIT;
    } else if (
      access?.accessType === AccessType.VIEW ||
      workspaceAccess?.accessType === AccessType.VIEW
    ) {
      return AccessType.VIEW;
    } else {
      return AccessType.RESTRICTED_VIEW;
    }
  }

  async ensureAccessWhenTransitioning(reportId: any, report: Report) {
    if (!this.adminHasAccess(report)) {
      this.cancelTransitionAndShowAccessModal(reportId);
    }
  }

  cancelTransitionAndShowAccessModal(reportId: string) {
    this.router.transitionTo('apps.app.reports.overview');
    this.loadReportAccessModal(reportId, () => {});
  }
}

declare module '@ember/service' {
  interface Registry {
    reportAccessService: ReportAccessService;
    'report-access-service': ReportAccessService;
  }
}
