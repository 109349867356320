/* RESPONSIBLE TEAM: team-ai-chatbot */
import Service, { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type DkimSettings from 'embercom/models/dkim-settings';

type ValidationResult = 'no_custom_sender' | 'default_reply_invalid';
type ValidationResponse = { isValid: boolean; type?: ValidationResult; message?: string };

export default class AiAgentSetupService extends Service {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare detectIntercomradesDomainService: $TSFixMe;
  @service declare intl: IntlService;
  defaultReplyInvalidMessage: string;

  constructor() {
    super(...arguments);
    this.defaultReplyInvalidMessage = this.intl.t(
      'operator.workflows.visual-builder.validations.fin.email.default-reply-invalid',
    );
  }

  get app() {
    return this.appService.app;
  }

  get validateEmailSetup(): ValidationResponse {
    if (this.detectIntercomradesDomainService.isIntercomradesDomain) {
      return { isValid: true };
    }

    // Emails are sent through the third party service in Fin Standalone apps.
    // E.g. via Zendesk of Salesforce
    if (this.app.canUseFinStandalone) {
      return { isValid: true };
    }

    if (!this.app.use_admin_app_email) {
      return {
        isValid: false,
        type: 'no_custom_sender',
        message: this.intl.t(
          'operator.workflows.visual-builder.validations.fin.email.no-custom-sender',
        ),
      };
    }

    if (!this.app.hasAdminReplyDefaultAddress) {
      return { isValid: false, type: 'no_custom_sender', message: this.defaultReplyInvalidMessage };
    }

    if (!this.validateEmailDefaultAddress.isValid) {
      return this.validateEmailDefaultAddress;
    }

    if (!this.validateDkimSettings.isValid) {
      return this.validateDkimSettings;
    }

    return { isValid: true };
  }

  get defaultReplyAddress() {
    return this.app.customEmailAddresses.find(
      (item: CustomEmailAddress) => item.id === this.app.admin_reply_default_address_id,
    );
  }

  get validateEmailDefaultAddress(): ValidationResponse {
    // If Promise is pending, we are still fetching the customEmailAddresses
    if (!this.app.customEmailAddresses.isPending) {
      if (!this.defaultReplyAddress || !this.defaultReplyAddress?.verified) {
        return {
          isValid: false,
          type: 'default_reply_invalid',
          message: this.defaultReplyInvalidMessage,
        };
      }
    }
    return { isValid: true };
  }

  get validateDkimSettings(): ValidationResponse {
    // If Promise is pending, we are still fetching the dkim_settings
    if (this.defaultReplyAddress && !this.app.dkim_settings.isPending) {
      let dkimSettings = this.app.dkim_settings.find((setting: DkimSettings) =>
        this.defaultReplyAddress?.email.toLowerCase().endsWith(`@${setting.domain}`),
      );
      if (!dkimSettings || !dkimSettings.validRecordExists || !dkimSettings.validDmarcExists) {
        return {
          isValid: false,
          type: 'default_reply_invalid',
          message: this.defaultReplyInvalidMessage,
        };
      }
    }
    return { isValid: true };
  }
}
