/* RESPONSIBLE TEAM: team-standalone */
import Service, { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { get } from 'embercom/lib/ajax';
import type ContentImportService from './content-import-service';
import { type TaskGenerator } from 'ember-concurrency';
import { EntityType } from 'embercom/models/data/entity-types';

interface ContentLibrarySummary {
  [key: number]: {
    entity_type: number;
    total_count: number;
    used_by_fin_count: number;
    additional_data: any;
  };
}

type ZendeskAdmin = {
  id: number;
  name: string;
  email: string;
  photo: string;
};

type SalesforceAdmin = {
  id: number;
  name: string;
  email: string;
  user_type: string;
};

type SalesforceQueue = {
  id: number;
  name: string;
};

export type ZendeskTicketsSetupData = {
  admins: Array<ZendeskAdmin>;
  email_addresses: Array<string>;
  live_trigger: { id?: number };
};

export type SalesforceSetupData = {
  admins: Array<SalesforceAdmin>;
  queues: Array<SalesforceQueue>;
};

export default class FinStandaloneService extends Service {
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare contentImportService: ContentImportService;

  @tracked contentLibrarySummary: ContentLibrarySummary = {};

  @tracked zendeskConfig!: ZendeskConfiguration;
  @tracked salesforceConfig!: SalesforceConfiguration;

  @tracked zendeskBrands: Array<any> = [];
  @tracked sunshineChannels: Array<any> = [];
  @tracked zendeskTicketsSetupData: ZendeskTicketsSetupData = {
    admins: [],
    email_addresses: [],
    live_trigger: { id: undefined },
  };

  @tracked salesforceSetupData: SalesforceSetupData = {
    admins: [],
    queues: [],
  };

  get hasContentForFin() {
    return Object.keys(this.contentLibrarySummary).some((key) => {
      return this.contentLibrarySummary[Number(key)].used_by_fin_count > 0;
    });
  }

  async initialize() {
    await RSVP.all([
      taskFor(this.loadZendeskConfig).perform(),
      taskFor(this.loadSalesforceConfig).perform(),
      this.store.findAll('conversation-attributes/descriptor'),
    ]);
    this.contentImportService.fetchContentImportSources();
    taskFor(this.fetchLibrarySummary).perform();
    taskFor(this.loadSunshineChannels).perform();
    taskFor(this.loadZendeskBrands).perform();
    taskFor(this.loadZendeskTicketsSetupData).perform();
    taskFor(this.loadSalesforceSetupData).perform();
  }

  @dropTask *loadZendeskConfig() {
    try {
      this.zendeskConfig = yield this.store.findRecord(
        'standalone/zendesk-configuration',
        this.appService.app.id,
      );

      if (!this.zendeskConfig) {
        this.zendeskConfig = this.store.createRecord('standalone/zendesk-configuration', {
          id: this.appService.app.id,
          sunshineIntegration: this.store.createRecord('standalone/sunshine-configuration', {
            id: this.appService.app.id,
          }),
        });
      }
    } catch (e) {
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  @dropTask *loadSalesforceConfig() {
    try {
      this.salesforceConfig = yield this.store.findRecord(
        'standalone/salesforce-configuration',
        this.appService.app.id,
      );

      if (!this.salesforceConfig) {
        this.salesforceConfig = this.store.createRecord('standalone/salesforce-configuration', {
          id: this.appService.app.id,
        });
      }
    } catch (e) {
      if (e.jqXHR?.status !== 404) {
        throw e;
      }
    }
  }

  @dropTask *loadZendeskBrands(): TaskGenerator<void> {
    if (this.zendeskConfig.isPendingSetupForTickets) {
      this.zendeskBrands = [];
      return;
    }

    let brands = yield get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/available_brands`,
      {
        app_id: this.appService.app.id,
      },
    );

    this.zendeskBrands = brands;
  }

  @dropTask *loadSunshineChannels(): TaskGenerator<void> {
    if (this.zendeskConfig.isPendingSetupForSunshine) {
      this.sunshineChannels = [];
      return;
    }

    let channels = yield get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/sunshine_channels`,
      {
        app_id: this.appService.app.id,
      },
    );

    this.sunshineChannels = channels;
  }

  @dropTask *fetchLibrarySummary(): TaskGenerator<void> {
    let result: ContentLibrarySummary = yield get(
      '/ember/content_service/contents/ai_content_library_summary',
      {
        app_id: this.appService.app.id,
      },
    ) as unknown as ContentLibrarySummary;

    // We don't have help center content in Fin Standalone. Let's remove it from the summary as it's confusing otherwise.
    delete result[EntityType.ArticleContent];

    this.contentLibrarySummary = result;
  }

  get isLoadingZendeskConfig() {
    return taskFor(this.loadZendeskConfig).isRunning;
  }

  get isLoadingLibrarySummary() {
    return taskFor(this.fetchLibrarySummary).isRunning;
  }

  @action async saveZendeskConfig() {
    try {
      await this.zendeskConfig.save();
      taskFor(this.loadZendeskTicketsSetupData).perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.setup.api.save-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('standalone.setup.api.save-error'),
      });
    }
  }

  @dropTask *loadZendeskTicketsSetupData(): TaskGenerator<void> {
    if (this.zendeskConfig.isPendingSetupForTickets) {
      this.zendeskTicketsSetupData = {
        admins: [],
        email_addresses: [],
        live_trigger: { id: undefined },
      };
      return;
    }

    this.zendeskTicketsSetupData = (yield get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/zendesk_tickets_config`,
      {
        app_id: this.appService.app.id,
      },
    )) as unknown as ZendeskTicketsSetupData;
  }

  get isLoadingZendeskTicketsSetupData() {
    return taskFor(this.loadZendeskTicketsSetupData).isRunning;
  }

  @dropTask *loadSalesforceSetupData(): TaskGenerator<void> {
    if (this.salesforceConfig.isPendingSetup) {
      this.salesforceSetupData = {
        admins: [],
        queues: [],
      };

      return;
    }

    let salesforceAdmins = yield get(
      `/ember/standalone/salesforce_configurations/${this.appService.app.id}/list_salesforce_users`,
      {
        app_id: this.appService.app.id,
      },
    );

    let salesforceQueues = yield get(
      `/ember/standalone/salesforce_configurations/${this.appService.app.id}/list_salesforce_queues`,
      {
        app_id: this.appService.app.id,
      },
    );

    this.salesforceSetupData = {
      admins: salesforceAdmins as Array<SalesforceAdmin>,
      queues: salesforceQueues as Array<SalesforceQueue>,
    };
  }

  get isLoadingSalesforceSetupData() {
    return taskFor(this.loadSalesforceSetupData).isRunning;
  }

  @action async saveSalesforceConfig() {
    try {
      await this.salesforceConfig.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('standalone.setup.api.save-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('standalone.setup.api.save-error'),
      });
    }
  }
}
