/* RESPONSIBLE TEAM: team-ai-chatbot */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ContentImportService from 'embercom/services/content-import-service';
import type Fin from 'embercom/models/operator/visual-builder/step/fin';

export default class FinHasAiContentReady extends BaseValidator {
  @service declare intl: IntlService;
  @service declare contentImportService: ContentImportService;
  @service declare appService: $TSFixMe;

  validate(_value: unknown, _options: ValidationOptions, model: Fin) {
    let aiContentLibrarySummary = this.contentImportService.aiContentLibrarySummary;
    let contentSummaries = Object.values(aiContentLibrarySummary);
    let usesResolutionsWithCustomAnswers = this.appService.app.usesResolutionsWithCustomAnswers;

    if (contentSummaries.length === 0 || model.customAnswerCount === -1) {
      return true; // to avoid the invalid 'flash' while the summaries and answers are being fetched
    }
    let hasAiContentReadyForFin = contentSummaries.some(
      (contentSummary) => contentSummary.used_by_fin_count > 0,
    );
    let hasLiveCustomAnswers = model.customAnswerCount > 0;

    // For Pricing 5 customers, we want live custom answers to be considered Fin content
    if (usesResolutionsWithCustomAnswers && hasLiveCustomAnswers) {
      return true;
    }

    if (!hasAiContentReadyForFin) {
      return this.intl.t('ai-agent.profiles.validation-errors.ai-content-not-ready');
    }

    return true;
  }

  static getDependentsFor() {
    return ['model.useAiAnswers', 'model.aiContentLibrarySummary', 'model.customAnswerCount'];
  }
}
