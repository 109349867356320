/* RESPONSIBLE TEAM: team-messenger */
import Service from '@ember/service';

import { tracked } from '@glimmer/tracking';
import { schedule } from '@ember/runloop';
import { type ArticleNavigationStackState } from 'embercom/services/help-center-navigation-service';
import type HelpCenterNavigationService from 'embercom/services/help-center-navigation-service';
import { inject as service } from '@ember/service';

type TabState = {
  sectionId?: SectionId;
  scrollPosition: number;
  navigationStackState?: ArticleNavigationStackState;
};

export type TabId = 'general' | 'content' | 'styling' | 'new-styling';

type GeneralSectionId =
  | 'language'
  | 'website'
  | 'domain'
  | 'tickets-portal'
  | 'analytics'
  | 'privacy'
  | 'integration'
  | 'image'
  | 'deletion';

type StylingSectionId =
  | 'shared-elements'
  | 'header'
  | 'hero'
  | 'layout-and-sections'
  | 'collection-page'
  | 'article-page'
  | 'footer';

// This can be deleted once FF psg-hc-ia is released
// <to be deleted>
type OldStylingSectionId =
  | 'welcome-message'
  | 'layout'
  | 'author'
  | 'descriptions'
  | 'table-of-contents'
  | 'related-articles'
  | 'search-bar'
  | 'fonts'
  | 'collection-card';
// <to be deleted>
export type SectionId = GeneralSectionId | OldStylingSectionId | StylingSectionId;

export default class HelpCenterSettingNavigationService extends Service {
  @tracked currentTabId: TabId = 'general';
  @tracked currentSectionId: SectionId | undefined;

  @service declare helpCenterNavigationService: HelpCenterNavigationService;

  scrollContainer: HTMLElement | undefined;
  scrollPosition = 0;
  navigationState: ArticleNavigationStackState | undefined;

  lastOpenedSectionByTab: Partial<{ [key in TabId]: TabState | undefined }> = {};

  reset() {
    this.currentTabId = 'general';
    this.currentSectionId = undefined;
    this.scrollPosition = 0;
    this.lastOpenedSectionByTab = {};
    this.navigationState = undefined;
    this.scrollContainer = undefined;
  }

  navigate(sectionId: SectionId, tabId?: TabId) {
    if (tabId) {
      this.currentTabId = tabId;
    }
    this.currentSectionId = sectionId;
    this.saveSectionsState();
  }

  updateLastScrollPosition(scrollPosition: number) {
    this.scrollPosition = scrollPosition;
    this.saveSectionsState();
  }

  updateNavigationState(state: ArticleNavigationStackState) {
    this.navigationState = state;
    this.saveSectionsState();
  }

  updateContainer(container: HTMLElement) {
    this.scrollContainer = container;
  }

  private saveSectionsState() {
    this.lastOpenedSectionByTab[this.currentTabId] = {
      sectionId: this.currentSectionId,
      scrollPosition: this.scrollPosition,
      navigationStackState: this.navigationState,
    };
  }

  navigateTab(tabId: TabId) {
    this.currentTabId = tabId;
    this.loadSectionsState();
  }

  loadSectionsState() {
    let lastState: TabState = this.lastOpenedSectionByTab[this.currentTabId] ?? {
      sectionId: undefined,
      scrollPosition: this.scrollPosition,
      navigationStackState: undefined,
    };

    let { sectionId, scrollPosition, navigationStackState } = lastState;

    this.currentSectionId = sectionId;
    this.scrollPosition = scrollPosition;
    this.navigationState = navigationStackState;

    schedule('afterRender', this, () => {
      if (this.scrollContainer) {
        this.scrollContainer.scrollTop = scrollPosition;
      }
    });
  }
}
