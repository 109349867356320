/* RESPONSIBLE TEAM: team-help-desk-ai */
import Service, { inject as service } from '@ember/service';
import { use } from 'ember-resources/util/function-resource';
import { type AiAssistSettingsResponse } from 'embercom/lib/ai-assist';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { request } from 'embercom/lib/inbox/requests';
import type Session from './session';
export default class AiAssistSettings extends Service {
  @service declare session: Session;

  get hasAnyAiAssistInInbox() {
    return Boolean(this.textTransformations || this.conversationSummarization);
  }

  get textTransformations() {
    return this.settingsData?.text_transformations || this.settingsData?.conversation_summarization;
  }

  get conversationSummarization() {
    return this.settingsData?.conversation_summarization || this.settingsData?.text_transformations;
  }

  get autoFill() {
    return this.settingsData?.auto_fill_tickets;
  }

  get copilot() {
    return this.settingsData?.copilot;
  }

  private get settingsData() {
    return this.aiAssistSettingsLoader.value;
  }

  @use aiAssistSettingsLoader = AsyncData<AiAssistSettingsResponse>(async ({ signal }) => {
    let response = await request(`/ember/ai_assist_settings?app_id=${this.session.workspace.id}`, {
      signal,
    });
    return response.json();
  });
}
