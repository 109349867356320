/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service from '@ember/service';
import { hrTime } from '@opentelemetry/core';
import ENV from 'embercom/config/environment';
import { timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import * as api from '@opentelemetry/api';
import { responsibleTeamFromTransition } from 'embercom/lib/transition-responsible-team';
import SpanData from 'embercom/lib/tracing/span-data';
import { setupOTEL, registerOTELInstrumentations } from 'embercom/lib/tracing/otel-setup';
import { anonymizeQueryParams } from 'embercom/lib/tracing/anonymize-query-params';
import { Span, Tracer } from '@opentelemetry/sdk-trace-base';
import { runInDebug } from '@ember/debug';
import { registerPerformanceObserver } from 'embercom/lib/tracing/performance-observer';
import { memoryHeapInfo } from 'embercom/lib/tracing/memory-heap-info';
import { getResource, parseUrl } from '@opentelemetry/sdk-trace-web';
import generateUUID from 'embercom/lib/uuid-generator';
import { getNumRequestsInFlight } from 'embercom/lib/inbox/requests';
import $ from 'jquery';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type RegionService from 'embercom/services/region-service';
import Ember from 'ember';

const errorRoutes = ['error', 'apps.error'];
const IMAGE_LOAD_TIMEOUT_MS = 1000;
const EMBER_MODEL_NAMES = [
  'conversation-attributes/descriptor',
  'inbox/ticket-type',
  'workflow-connector/insertable-action',
  'billing/customer',
  'conversation-attributes/list-option',
  'fin-free-usage-window',
  'outbound-subscriptions/subscription-type',
  'custom-objects/object-type',
  'objects/attribute-descriptor',
  'product',
  'plan',
  'segment',
  'sdk-app',
  'attribute',
  'people/attribute-option',
  'display-attribute-setting',
  'tag',
  'admin',
  'external-plan',
  'people/qualification-attribute',
  'feature',
  'permission',
  'tagging',
  'app',
  'browser-locale',
  'billing-feature',
  'settings/calling',
];

function getTransitionInformation(transition: $TSFixMe, router: RouterService) {
  let fromRoute = transition?.from?.name;
  let toRoute = transition?.to?.name ?? router.currentRouteName;
  return {
    fromRoute,
    toRoute,
  };
}

function safeStringify(value: unknown): string | null {
  try {
    return JSON.stringify(value);
  } catch {
    return null;
  }
}

export default class TracingService extends Service {
  @service declare router: RouterService;
  @service declare regionService: RegionService;
  @service declare store: Store;

  tracer: $TSFixMe;
  spanHierarchy: $TSFixMe; // References SpanData object - linked list of root spans.
  idleDetectionIterations = 0;
  // OTEL has getResource API to fetch PerformanceResourceTiming entry but it requires to keep track
  // of the entries that were already found and used.
  usedResources = new WeakMap();
  uuid = generateUUID();
  isTracingEnabled: boolean;

  // spanData hash keys are span IDs and values are parent hierarchy objects.
  // When a given span is started/finished we need to update its parent info:
  // * Increment activeChildSpanIDs and childrenSeen
  // * Update endTime to point to the endTime of most recently ended span
  spanData: $TSFixMe;
  lastVisibilityChangeAt?: number;
  usedNetworkPorts?: Set<$TSFixMe>;
  sessionUsedNetworkPorts?: Set<$TSFixMe>;
  currentNetworkPort?: $TSFixMe;

  addRollupField(name: string, value: $TSFixMe) {
    this.spanHierarchy?.addRollupField(name, value);
  }

  constructor() {
    super(...arguments);
    this.isTracingEnabled = this.tracingEnabled();

    if (this.isTracingEnabled) {
      this.initialize();
    }
  }

  numRequestsInFlight() {
    // $.active is not on the types but definitely exists
    let jQuery = $ as JQueryStatic & { active: number };
    return jQuery.active + getNumRequestsInFlight();
  }

  initialize() {
    this.isTracingEnabled = true;
    this.tracer = setupOTEL(this.regionService.telemetryBaseUrl());
    this.instrumentEmberRouter();
    this.spanData = {};
    this.lastVisibilityChangeAt = performance.now();
    this.usedNetworkPorts = new Set();
    this.sessionUsedNetworkPorts = new Set();
    this.currentNetworkPort = null;

    registerPerformanceObserver(this);

    // The following code monkey-patches Span start and end so that we can hook in with our own callbacks.
    let originalStartSpan = Tracer.prototype.startSpan;
    let onSpanStart = this.onSpanStart.bind(this);

    // @ts-ignore TODO - not sure how to type this
    Tracer.prototype.startSpan = function (
      name: string,
      options: $TSFixMe,
      context: $TSFixMe,
      parentSpanData: $TSFixMe,
    ) {
      let span = originalStartSpan.apply(this, [name, options, context]);
      try {
        onSpanStart(span, context, parentSpanData);
      } catch (error) {
        console.error('[Tracing service] onSpanStart failed:', error);
      }
      return span;
    };

    let that = this;
    let originalSpanEnd = Span.prototype.end;
    let onSpanEnd = this.onSpanEnd.bind(this);
    Span.prototype.end = function (endTime) {
      this.setAttributes({
        '_internal.tracing_service_guid': that.uuid,
        '_internal.system_clock_end': Date.now(),
        '_internal.idle_detection_iterations': that.idleDetectionIterations,
        ...that.spanData[this.spanContext().spanId]?.rollupFields,
      });

      originalSpanEnd.apply(this, [endTime]);
      try {
        onSpanEnd(this);
      } catch (error) {
        console.error('[Tracing service] onSpanEnd failed:', error);
      }
    };

    let originalOnError = Ember.onerror;
    Ember.onerror = (error: $TSFixMe) => {
      // Ignore network errors
      if (!(typeof error === 'object' && error.jqXHR)) {
        this.onError(error, 'Ember');
      }
      originalOnError.apply(this, [error]);
    };

    this.startTrace({
      name: 'Page Load',
      attributes: { 'http.url': window.location.href },
      startTime: window.performance?.timeOrigin || window.performance?.timing?.fetchStart,
      autoEndOnIdle: true,
    });
    this.spanHierarchy.setGlobalTags({
      page_load: true,
      '_internal.trace_performance_now': 0,
    });

    registerOTELInstrumentations({
      pageLoadRootSpan: this.getActiveRootSpan(),
      getActiveRootSpan: this.getActiveRootSpan.bind(this),
      onSpanEnd: this.onSpanEnd.bind(this),
    });

    window.addEventListener('error', (event) => {
      let error = event instanceof ErrorEvent ? event.error : event;
      this.onError(error, 'Window');
    });

    window.addEventListener('visibilitychange', this.visibilityChangedCallback.bind(this), false);
  }

  tracingEnabled() {
    let tracingEnabledMeta = document.querySelector("meta[name='tracing_enabled']") as
      | HTMLMetaElement
      | undefined;
    let isStagingEnvironment = window.location.hostname.startsWith(ENV.APP.stagingHostname);

    return !isStagingEnvironment && tracingEnabledMeta?.content === 'true';
  }

  onSpanStart(span: $TSFixMe, _context: $TSFixMe, parentSpanData: $TSFixMe) {
    this.spanHierarchy?.maybeDeactivate(span);

    // Invalid spans are never finished. Hence, should not be added as an active child.
    if (span._spanContext.spanId === api.INVALID_SPANID) {
      return;
    }

    if (span.name?.toString().startsWith('HTTP')) {
      span.setAttributes({
        'network.requests_in_flight': this.numRequestsInFlight(),
      });
    }

    span.setAttributes({
      ember_route: this.router?.currentRouteName,
      current_origin: performance.timeOrigin,
      '_internal.performance_now': performance.now(),
      '_internal.system_clock_start': Date.now(),
      current_url_pathname: window.location.pathname,
      since_last_visibility_change_ms: Math.round(
        performance.now() - (this.lastVisibilityChangeAt || 0),
      ),
      visibility_state: this.visibilityState(),
      ...memoryHeapInfo(),
    });
    let parent = parentSpanData || this.spanHierarchy;
    // We store reference for every new span so that, when it is finished, we can update its parent info.
    this.setSpanData(span, parent);
    if (!this.isCurrentRoot(span) && parent) {
      let [firstActiveChild] = parent.activeChildSpanIDs;
      span.setAttributes({
        '_internal.last_sibling_end_time': parent.endTime,
        '_internal.root_span_exists': parent.span !== undefined,
        '_internal.root_span_id': parent.span?.spanContext()?.spanId,
        '_internal.root_span_active_children_count': parent.activeChildSpanIDs.size,
        '_internal.root_span_first_active_child_id': firstActiveChild,
        '_internal.root_span_first_active_child_name': this.spanData[firstActiveChild]?.span.name,
        '_internal.root_span_first_active_child_resource':
          this.spanData[firstActiveChild]?.span.attributes['resource'],
      });
      parent.addActiveChild(span._spanContext.spanId);
    }
  }

  isCurrentRoot(span: $TSFixMe) {
    return this.spanHierarchy?.span._spanContext.spanId === span._spanContext.spanId;
  }

  // This is called either when Span is ended OR when applyCustomAttributesOnSpan is called upon XHR/Fetch request
  // completion. Those network instrumentations end the Span in setTimeout which could be delayed for minutes.
  // We want to end the spans immediately after request completion though to ensure the trace is not artificially extended.
  onSpanEnd(span: $TSFixMe) {
    if (span.name?.startsWith('HTTP')) {
      span.setAttributes({
        'network.requests_in_flight_at_end': this.numRequestsInFlight(),
      });
      let remotePort = span.attributes['remote_port'];
      if (remotePort) {
        this.usedNetworkPorts?.add(remotePort);
        this.sessionUsedNetworkPorts?.add(remotePort);
      }
      let portAttributes: Record<string, number | boolean | undefined> = {
        'network.used_ports_count': this.usedNetworkPorts?.size,
        'network.session_used_ports_count': this.sessionUsedNetworkPorts?.size,
      };
      // Set initial value for the first port we see.
      if (!this.currentNetworkPort && remotePort) {
        this.currentNetworkPort = remotePort;
      }
      if (this.currentNetworkPort && remotePort && this.currentNetworkPort !== remotePort) {
        this.currentNetworkPort = remotePort;
        portAttributes['network.connection_established'] = true;
      }
      this.tagActiveSpan(portAttributes);
      span.setAttributes(portAttributes);
    }

    let parent = this.spanData[span._spanContext.spanId]?.parent;
    // Span is ended - no need to store reference for it anymore.
    delete this.spanData[span._spanContext.spanId];
    if (parent) {
      // Update parents endTime with the most recent value.
      // When we finish a root span we want its endTime to be the same as the endTime of its "slowest" child.
      parent.endTime = span.ended ? span.endTime : hrTime();
      parent.removeActiveChild(span._spanContext.spanId);
    }
    if (this.isCurrentRoot(span)) {
      // We have finished the span that happened to be a current root.
      // Hence, we need to update the spanHierarchy reference to its parent and re-schedule root end task.
      this.spanHierarchy = this.spanHierarchy.parent;
      taskFor(this.scheduleEndRootSpan).perform();
    }
  }

  getActiveRootSpan() {
    return this.getActiveRoot()?.span;
  }

  getActiveRoot() {
    let span = this.spanHierarchy?.span;
    if (!span) {
      return;
    }

    // This context is of Context type (e.g. Zone.js context)
    let context = api.trace.setSpan(api.context.active(), span);
    // This context is of SpanContext type - stores info about the span itself.
    let spanContext = api.trace.getSpanContext(context);
    // Our patched XHR/Fetch instrumentations rely on getActiveRootSpan() – if it returns a span then a child span will be created.
    // However, if the span context is invalid OTEL starts a new trace resulting in those child spans becoming single-span traces.
    // We don't want those solitary network spans, so we should not return a span here if its span context is invalid for whatever reason.
    if (!spanContext || !api.trace.isSpanContextValid(spanContext)) {
      return;
    }

    return this.spanHierarchy;
  }

  tagRootSpan(tags: $TSFixMe) {
    this.spanHierarchy?.topLevelRoot()?.span?.setAttributes(tags);
  }

  tagActiveSpan(tags: $TSFixMe) {
    let activeSpan = this.getActiveRoot()?.span;
    if (!activeSpan) {
      activeSpan = api.trace.getSpan(api.context.active());
    }

    activeSpan?.setAttributes(tags);
  }

  addEvent(name: string, tags?: $TSFixMe) {
    this.getActiveRootSpan()?.addEvent(name, tags);
  }

  // Sets currently active root span by updating spanHierarchy reference to a new span data object.
  setActiveRootSpan(span: $TSFixMe) {
    this.spanHierarchy = this.setSpanData(span);
  }

  setSpanData(span: $TSFixMe, parentSpanData?: $TSFixMe) {
    if (!span) {
      // This should never happen but, if the span is missing, we can't proceed further.
      return;
    }

    if (!this.spanData[span._spanContext.spanId]) {
      this.spanData[span._spanContext.spanId] = new SpanData(
        span,
        parentSpanData || this.spanHierarchy,
      );
    }
    return this.spanData[span._spanContext.spanId];
  }

  // Pre-emptively ends existing trace by ending every root span in the hierarchy
  haltTrace(haltReason?: string) {
    try {
      let rootSpan = this.spanHierarchy;

      while (rootSpan) {
        if (rootSpan.span) {
          rootSpan.span.setAttributes({
            trace_halted: true,
            halt_reason: haltReason,
          });
          rootSpan.span.end();
        }
        rootSpan = rootSpan.parent;
      }
    } finally {
      // No matter what happens during halt we should always unset the span hierarchy.
      // We do not trust onSpanEnd to unset span hierarchy as it is not called for NonRecordingSpan.
      // We need to get rid of the Span.prototype.end monkey patch in favor of a custom span processor with onEnd override.
      this.spanHierarchy = undefined;
      this.spanData = {};
    }
  }

  startTrace({
    name,
    attributes,
    startTime,
    autoEndOnIdle,
    haltReason,
  }: {
    name: string;
    attributes: $TSFixMe;
    startTime: $TSFixMe;
    autoEndOnIdle: boolean;
    haltReason?: string;
  }) {
    if (!this.isTracingEnabled) {
      return;
    }

    // We still want to preserve currently active trace even if it hasn't finished yet.
    // Hence, we have to force-fully halt to make sure spans are exported.
    this.haltTrace(haltReason);
    this.usedNetworkPorts?.clear();

    let clockDrift = Date.now() - (performance.timeOrigin + performance.now());
    let newRootSpan = this.tracer.startSpan(name, {
      attributes: {
        ...attributes,
        clock_drift: clockDrift,
        original_origin: performance.timeOrigin,
        current_origin: performance.timeOrigin,
        ...this._emberModelStats(),
      },
      startTime,
    });

    this.setActiveRootSpan(newRootSpan);
    this.spanHierarchy.setGlobalTags({
      clock_drift: clockDrift,
      original_origin: performance.timeOrigin,
      '_internal.trace_performance_now': performance.now(),
    });

    if (autoEndOnIdle) {
      this.idleDetectionIterations = 0;
      taskFor(this.scheduleEndRootSpan).perform();
    }

    return newRootSpan;
  }

  startChildSpan(name: string, resource: string, attributes = {}, startTime = hrTime()) {
    if (!this.isTracingEnabled) {
      return;
    }
    if (!this.getActiveRootSpan()) {
      return;
    }
    let parentContext = api.trace.setSpan(api.context.active(), this.getActiveRootSpan());

    let span = this.tracer.startSpan(
      name,
      {
        attributes: {
          ...attributes,
          resource,
        },
        startTime,
      },
      parentContext,
    );

    return span;
  }

  endChildSpan({
    span,
    endTime = hrTime(),
    attributes = {},
  }: {
    span: $TSFixMe;
    endTime?: $TSFixMe;
    attributes?: $TSFixMe;
  }) {
    if (span) {
      span.setAttributes({ ...attributes, manually_ended: true });
      span.end(endTime);
    }
  }

  trackImageLoad(image: HTMLImageElement) {
    if (!this.isTracingEnabled) {
      return;
    }

    // We want to fix the trace root the image will be attached to as we want to avoid images leaking
    // too other traces if the image load takes time.
    let parentRoot = this.getActiveRoot();

    if (!parentRoot) {
      return;
    }

    let startTime = hrTime();
    let relativeStartTime = performance.now();
    let imageData = parseUrl(image.src);
    let visible = false;
    image.onload = () => {
      image.onload = null;

      if (visible) {
        // Fetching PerformanceResourceTiming for the image.
        // Most of the values (e.g. decodedBodySize or connectStart) will be set to 0 – browser
        // doesn't expose detailed info for cross-origin requests (most inbox images are).
        // However, it is useful to know when the image load request started using startTime field.
        let resources = performance.getEntriesByName(image.src, 'resource');
        let resource = getResource(
          imageData.href,
          startTime,
          hrTime(),
          resources as $TSFixMe,
          this.usedResources as $TSFixMe,
          'img',
        )?.mainRequest;

        // If we can't find a resource timing info for the image we don't know whether it was loaded immediately or
        // after a delay when it became visible (image conversation parts with loading='lazy').
        if (!resource) {
          this.debugLog('image resource not found');
          this.tagRootSpan({ '_internal.no_resource_for_visible_image': true });
          return;
        }

        // If the image load started more than IMAGE_LOAD_TIMEOUT_MS after the image HTML tag was created
        // we suspect the image wasn't visible initially and the browser made a call not to load it right away.
        // E.g. customer opened a conversation stream, and then scrolled up to see the earlier messages with the image.
        // We don't want to instrument such images as they will skew our understanding of when the image load
        // happened within the trace.
        let imageLoadDelay = resource.startTime - relativeStartTime;
        if (imageLoadDelay > IMAGE_LOAD_TIMEOUT_MS) {
          this.debugLog(`image load was delayed for ${imageLoadDelay}`);
          this.tagRootSpan({
            '_internal.delayed_image_load': true,
            '_internal.image_load_delay_ms': imageLoadDelay,
          });
          return;
        }

        let parentContext = api.trace.setSpan(api.context.active(), parentRoot.span);
        let span = this.tracer.startSpan(
          'HTTP GET',
          {
            startTime,
            attributes: {
              resource: `image:${imageData.host}`,
              image_load: true,
              'http.url': imageData.href,
              'http.host': imageData.host,
              'http.scheme': imageData.protocol,
              '_internal.image_load_delay_ms': imageLoadDelay,
              'network.responseEnd_ms': resource.responseEnd - resource.fetchStart,
              ...parentRoot.globalTags,
            },
          },
          parentContext,
        );
        span.end(hrTime());
      }
    };

    requestAnimationFrame(() => {
      let rect = image.getBoundingClientRect();
      visible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    });
  }

  @restartableTask *scheduleEndRootSpan() {
    // repeat until all root spans are finished
    while (this.spanHierarchy) {
      yield timeout(ENV.APP.tracing.endTimeout);
      this.idleDetectionIterations += 1;

      let rootSpan = this.spanHierarchy;

      while (rootSpan) {
        if (rootSpan.isTraceTimedOut()) {
          this.haltTrace('timeout');
          return;
        } else {
          if (rootSpan.isReadyForCompletion()) {
            rootSpan.span.setAttribute(
              '_internal.idle_detection_iterations',
              this.idleDetectionIterations,
            );
            rootSpan.span.end(rootSpan.endTime);
          }
          rootSpan = rootSpan.parent;
        }
      }
    }
  }

  onRouteWillChange(transition: $TSFixMe, routerService: RouterService) {
    let { fromRoute, toRoute } = getTransitionInformation(transition, routerService);
    if (toRoute.endsWith('.loading') || toRoute.endsWith('_loading')) {
      // We can't create spans for sub-states as they will be treated as top-level transitions based on the logic below.
      return;
    }
    let isRootTransition =
      !transition.isCausedByAbortingReplaceTransition &&
      !transition.isCausedByAbortingTransition &&
      !transition.isCausedByInitialTransition;
    // If there's no existing spanHierarchy (no active trace) – this is a top-level transition by definition.
    // It is also a top-level transition if the current root span is not an immediate child of a Page Load.
    let isTopLevelTransition =
      isRootTransition && (!this.spanHierarchy || this.spanHierarchy.span.name !== 'Page Load');

    if (errorRoutes.includes(toRoute)) {
      isTopLevelTransition = false;
      isRootTransition = false;
      this.tagRootSpan({ error_route_rendered: true });
    }

    let spanName = 'routeTransition';
    let spanResource = `route:${toRoute}`;
    let description = `route:${fromRoute} -> route:${toRoute}`;
    let toQueryParams;
    try {
      toQueryParams = JSON.stringify(anonymizeQueryParams(transition.to?.queryParams));
    } catch (e) {
      runInDebug(() => console.error(e));
    }
    let spanAttributes = {
      resource: spanResource,
      'transition.fromRoute': fromRoute,
      'transition.toRoute': toRoute,
      'transition.description': description,
      'transition.intent': transition.intent?.constructor.name,
      'http.url': transition.intent?.url,
      'transition.urlMethod': transition.urlMethod,
      'transition.isIntermediate': transition.isIntermediate,
      'transition.isCausedByAbortingReplaceTransition':
        transition.isCausedByAbortingReplaceTransition,
      'transition.isCausedByAbortingTransition': transition.isCausedByAbortingTransition,
      'transition.isCausedByInitialTransition': transition.isCausedByInitialTransition,
      'transition.toQueryParams': toQueryParams,
      responsible_team: responsibleTeamFromTransition(transition),
    };

    let span;
    // Start a new trace on top-level route transition
    if (isTopLevelTransition) {
      span = this.startTrace({
        name: spanName,
        attributes: spanAttributes,
        startTime: hrTime(),
        autoEndOnIdle: true,
        haltReason: description,
      });
    } else {
      span = this.startChildSpan(spanName, spanResource, spanAttributes);
      this.setActiveRootSpan(span);
    }

    let globalTags = {
      'parent_transition.fromRoute': fromRoute,
      'parent_transition.toRoute': toRoute,
      'parent_transition.description': description,
      'parent_transition.url': transition.intent?.url,
      responsible_team: responsibleTeamFromTransition(transition),
    };
    if (isRootTransition) {
      globalTags = Object.assign(globalTags, {
        'root_transition.fromRoute': fromRoute,
        'root_transition.toRoute': toRoute,
        'root_transition.description': description,
        'root_transition.url': transition.intent?.url,
      });
    }
    this.spanHierarchy?.setGlobalTags(globalTags);

    taskFor(this.scheduleEndRootSpan).perform();
    return span;
  }

  startRootSpan({
    name,
    resource,
    attributes = {},
  }: {
    name: string;
    resource: string;
    attributes?: $TSFixMe;
  }) {
    if (!this.isTracingEnabled) {
      return;
    }

    if (this.getActiveRootSpan()) {
      let span = this.startChildSpan(name, resource, attributes);
      this.setActiveRootSpan(span);
    } else {
      this.startTrace({
        name,
        attributes: {
          ...attributes,
          resource,
        },
        startTime: hrTime(),
        autoEndOnIdle: true,
      });
    }
  }

  // Creates an active OTEL span – any span created inside `fn` will be a child of this span.
  // It doesn't set the new span as the active root span from Tracing service perspective.
  // As a result, network requests are not captured as children of this span.
  // If no active root span is set in Tracing service, this span will be the root span.
  async inSpan(
    {
      name,
      resource,
      attributes = {},
      enabled = true,
    }: { name: string; resource: string; attributes?: $TSFixMe; enabled?: boolean },
    fn: (span?: $TSFixMe) => any,
  ) {
    if (!enabled || !this.isTracingEnabled) {
      return await fn();
    }

    let parentContext;
    let currentSpan = api.trace.getSpan(api.context.active());
    // Only use active root from Tracing service if there's no OTEL native active span already
    if (this.getActiveRootSpan() && !currentSpan) {
      parentContext = api.trace.setSpan(api.context.active(), this.getActiveRootSpan());
    }

    return await this.tracer.startActiveSpan(
      name,
      { attributes: { resource, ...attributes } },
      parentContext,
      async (span: $TSFixMe) => {
        try {
          return await fn(span);
        } catch (e) {
          this.setSpanError(span, e);
          throw e;
        } finally {
          span.end();
        }
      },
    );
  }

  instrumentEmberRouter() {
    this.router.on('routeWillChange', (transition) => {
      this.onRouteWillChange(transition, this.router);
    });
  }

  onError(error: $TSFixMe, origin: $TSFixMe) {
    let span = this.getActiveRootSpan();
    if (!span) {
      return;
    }
    this.setSpanError(span, error, origin);
  }

  setSpanError(span: $TSFixMe, error: $TSFixMe, origin?: $TSFixMe) {
    if (!error) {
      error = 'unknown';
    }
    span.setAttributes({
      error_origin: origin,
      error: true,
      error_raw: error.toString(),
      error_object: safeStringify(error),
    });
    span.recordException(error);
  }

  visibilityChangedCallback(_: $TSFixMe) {
    this.lastVisibilityChangeAt = performance.now();
    if (this.visibilityState() === 'hidden') {
      this.addEvent('document_hidden');
    } else if (this.visibilityState() === 'visible') {
      this.addEvent('document_visible');
    }
  }

  visibilityState() {
    if (typeof document.visibilityState === 'undefined') {
      return;
    }

    return document.visibilityState;
  }

  willDestroy() {
    super.willDestroy();
    document.removeEventListener('visibilitychange', this.visibilityChangedCallback);
  }

  debugLog(...args: any[]) {
    runInDebug(() => console.info('Tracing Service: ', ...args));
  }

  _emberModelStats() {
    try {
      let emberDataStats: Record<string, any> = {};
      let sortedModelTypes = EMBER_MODEL_NAMES.map((modelName) => {
        return {
          modelName,
          length: this.store.peekAll(modelName).length as number,
        };
      });
      sortedModelTypes.sort((a, b) => b.length - a.length);
      let topModelTypes = sortedModelTypes.slice(0, 10);
      topModelTypes.forEach((item) => {
        emberDataStats[`ember_model_stats.${item.modelName}`] = item.length;
      });

      return emberDataStats;
    } catch (e) {
      return {};
    }
  }
}
