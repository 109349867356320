/* RESPONSIBLE TEAM: team-tickets-1 */

import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import Service from '@ember/service';

export default class ConditionalAttributesService extends Service {
  attributeDescriptors: ConversationAttributeDescriptor[] = [];
  attributeValues: Record<string, ConversationAttributeSummary> = {};

  initialize(
    attributeDescriptors: ConversationAttributeDescriptor[],
    attributeValues: Record<string, ConversationAttributeSummary>,
  ) {
    this.attributeDescriptors = attributeDescriptors;
    this.attributeValues = attributeValues;
    return this;
  }

  private hasNoConditions(descriptor: ConversationAttributeDescriptor): boolean {
    return descriptor.conditions.length === 0;
  }

  private anyControllerMatch(descriptor: ConversationAttributeDescriptor): boolean {
    return descriptor.conditions.some(
      (condition) =>
        this.attributeValues[condition.controllingDescriptorId]?.value ===
        condition.controllingListOptionId,
    );
  }

  private hasAttributeChanged(): boolean {
    return Object.values(this.attributeValues).some((attribute) => attribute.isUpdated);
  }

  private evaluateConditionsOnUpdate(): ConversationAttributeDescriptor[] {
    return this.attributeDescriptors.filter(
      (descriptor) => this.hasNoConditions(descriptor) || this.anyControllerMatch(descriptor),
    );
  }

  visibleAttributes(): ConversationAttributeDescriptor[] {
    if (this.hasAttributeChanged()) {
      return this.evaluateConditionsOnUpdate();
    }

    return this.attributeDescriptors?.filter((descriptor) => {
      let hasValueSet = Boolean(this.attributeValues[descriptor.id]?.value);
      return this.hasNoConditions(descriptor) || hasValueSet || this.anyControllerMatch(descriptor);
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    conditionalAttributesService: ConditionalAttributesService;
    'conditional-attributes-service': ConditionalAttributesService;
  }
}
