/* RESPONSIBLE TEAM: team-proactive-support */
import Service, { inject as service } from '@ember/service';

export default class EducateEventService extends Service {
  @service intercomEventService;

  owner = 'educate';
  eventName = 'educate-event';

  trackEducateArticleEvent(article, metadata = {}) {
    metadata.owner = this.owner;
    metadata.object = 'article';
    metadata.article_id = article.get('id');
    if (!metadata.collection_id) {
      metadata.collection_id = article.get('collection.id');
    }
    if (!metadata.section_id) {
      metadata.section_id = article.get('section.id');
    }

    let eventName = metadata.eventName || this.eventName;
    delete metadata['eventName'];

    this.intercomEventService.trackEvent(eventName, metadata);
  }
}
