/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';

/**
 * This service is a wrapper around ConversationsService.closeConversation(conversation),
 * which allows you to register extra checks and fallbacks that you'd like to perform before fully closing a conversation.
 *
 * Example:
 * See close-button-component.js to see how this service is used to check for missing required attributes before closing an active converstion.
 */
export default class ConversationCloseService extends Service {
  @service conversationsService;

  checks = new Map();

  registerCheck(id, check) {
    this.checks.set(id, check);
  }

  deleteCheck(id) {
    this.checks.delete(id);
  }

  closeConversation(conversation, onSuccess) {
    for (let { doCheck, onError } of this.checks.values()) {
      if (!doCheck(conversation)) {
        onError(conversation);
        return false;
      }
    }
    this.conversationsService.closeConversation(conversation);
    if (onSuccess) {
      onSuccess();
    }
    return true;
  }
}
