/* RESPONSIBLE TEAM: team-product-guidance */
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import { Step } from 'embercom/objects/onboarding/checklist/step';
import { action } from '@ember/object';

export default class GreatGuidanceService extends Service {
  @tracked nextStepId?: string;
  @tracked currentStepId?: string;
  @tracked steps: Step[] = [];
  @tracked isFloatingWidgetExpanded = false;
  @tracked hideFloatingWidget = true;

  get nextStep(): Step | undefined {
    if (this.nextStepId) {
      let nextStep = this.steps.find((step) => step.identifier === this.nextStepId);
      if (nextStep && nextStep.state === 'available') {
        return nextStep;
      }
    }

    return this.steps.find((step) => step.state === 'available');
  }

  @action
  async fetchSteps(appId: string) {
    let guide: any = await get(`/ember/onboarding/home/guide?app_id=${appId}`);
    this.hideFloatingWidget = !guide.show_widget;
    this.steps = this.flattenSteps(guide);
    this.currentStepId = this.nextStep?.identifier;
  }

  flattenSteps(guide: any) {
    let allSteps: Step[] = [];
    guide.levels.forEach((level: any) => {
      level.steps.forEach((step: any) => {
        let deserialisedStep: Step = Step.deserialize(step);
        allSteps.push(deserialisedStep);
      });
    });
    return allSteps;
  }
}
