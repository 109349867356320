/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import { type Metric } from 'embercom/objects/reporting/unified/metrics/types';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SERIES_COLOR_PALETTE } from 'embercom/lib/reporting/flexible/constants';
// @ts-ignore
import MurmurHash3 from 'imurmurhash';
import {
  TEAM_PROPERTY_IDENTIFIERS,
  TEAMMATE_PROPERTY_IDENTIFIERS,
} from 'embercom/lib/reporting/flexible/constants';

export interface ViewConfig {
  metrics: Metric[];
  chartType: string;
  formatUnit: {
    unit: string;
    displayUnit: string | null;
  };
  skipLabelsForLargeSeries: boolean;
  labelMappingFunction: any; //TODO: figure out what this is
  legendMappingFunction: any; //TODO: figure out what this is
  labelStyleOverrides: Record<PropertyKey, never>;
  shouldEnableHoverState: boolean;
  heightType: string;
  useDarkTooltips: boolean;
  colorByPoint: boolean;
  showDataLabels: boolean;
  columnChart?: Record<string, string>;
  showPercentages?: boolean;
  seriesColors?: string[];
  legend?: Record<string, Labeler>;
  yAxis?: Axis;
  tooltipFormatter?: Labeler;
  disableLegend?: boolean;
  showLegendInTooltips?: boolean;
}

export interface DonutSeriesData {
  color?: any;
  name: Value;
  y: OptionalNumber;
}

interface Axis {
  tickInterval: number;
  min?: number;
  max?: number;
}

export type Labeler = () => string | undefined;
export interface SeriesAreaData {
  x: Value;
  y: OptionalNumber;
  name: Value;
}

export type Value = number | string;
export type OptionalNumber = number | null;

export interface Point {
  x: Value;
  y: number;
}

export interface Group {
  aggregations: Aggregation[];
  values: Value[];
  name: string;
  type: string;
}

export interface RawChartData {
  groups: Group[];
  name: string; // metric name
  aggregations?: Aggregation[];
}

export interface Aggregation {
  name: string;
  values: number[];
}

const SYNTHETIC_DATA_STORAGE_KEY = 'reporting_use_synthetic_data';

export default class ReportingChartService extends Service {
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked useSyntheticData = this.initialSyntheticDataValue;

  colorMaps = new Map();

  convertNullsToZeros(data: SeriesAreaData[]): SeriesAreaData[] {
    return data.map((point) => {
      return {
        x: point.x,
        y: point.y || 0,
        name: point.name,
      };
    });
  }

  getLabelFunction(seriesName: string, legend?: Record<string, () => string>): Labeler {
    let defaultFunc = () => undefined;
    return legend?.[seriesName] || defaultFunc;
  }

  get initialSyntheticDataValue() {
    return (
      this.appService.app.canUseSyntheticReportingData &&
      sessionStorage.getItem(SYNTHETIC_DATA_STORAGE_KEY) === 'true'
    );
  }

  @action
  toggleSyntheticData() {
    this.useSyntheticData = !this.useSyntheticData;
    sessionStorage.setItem(SYNTHETIC_DATA_STORAGE_KEY, this.useSyntheticData.toString());
  }

  /* Given an attribute (e.g. 'teammate_id') and series name (e.g. '123', a teammate's ID), return a color for the series
   *  For teammate and team attributes, group them together to ensure we use a consistent color per teammate/team
   *  If the series has a color set, return that color
   *  If the series doesn't have a color set, generate a preferred color for it based on a hashing function
   *  If the preferred color is taken, get the least used color from the palette
   */
  getColorForSeries(attributeId: string, seriesName: string) {
    let attribute = this.getGroupedAttributeIdentifier(attributeId);

    if (!this.colorMaps.has(attribute)) {
      this.colorMaps.set(attribute, new Map());
    }
    let colorMapForAttribute = this.colorMaps.get(attribute);

    // if the series doesn't have a color set, generate a color for it
    if (!colorMapForAttribute.has(seriesName)) {
      let hash = MurmurHash3(seriesName).result();
      let colorIndex = hash % SERIES_COLOR_PALETTE.length;
      let color = SERIES_COLOR_PALETTE[colorIndex];

      let colorUsageMap: any = Array.from(colorMapForAttribute.values()).reduce(
        (acc: Record<string, number>, color: string) => {
          acc[color] = (acc[color] || 0) + 1;
          return acc;
        },
        {},
      );

      // If preferred color is taken, get the least used color
      if (color in colorUsageMap) {
        color = this.getLeastUsedColor(colorUsageMap);
      }
      colorMapForAttribute.set(seriesName, color);
    }

    return colorMapForAttribute.get(seriesName);
  }

  getGroupedAttributeIdentifier(identifier: string) {
    if (TEAM_PROPERTY_IDENTIFIERS.has(identifier)) {
      return 'team';
    } else if (TEAMMATE_PROPERTY_IDENTIFIERS.has(identifier)) {
      return 'teammate';
    }
    return identifier;
  }

  /* Find the least used color from the palette
   * If a color is not used (i.e. usage is 0), return it.
   * Otherwise, all colors are used; return the color with the least usage.
   */
  getLeastUsedColor(colorUsageMap: any) {
    let minUsage = Infinity;
    let leastUsedColor = SERIES_COLOR_PALETTE[0];

    for (let color of SERIES_COLOR_PALETTE) {
      let usage = colorUsageMap[color] || 0;
      if (usage === 0) {
        return color;
      }

      if (usage < minUsage) {
        minUsage = usage;
        leastUsedColor = color;
      }
    }

    return leastUsedColor;
  }

  resetColors() {
    this.colorMaps.clear();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'reporting-chart-service': ReportingChartService;
  }
}
