/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service, { inject as service } from '@ember/service';
import { InboxMentionsStatus, InboxStateOption } from 'embercom/models/data/inbox/inbox-filters';
import storage from 'embercom/vendor/intercom/storage';
import { type SortState } from 'embercom/components/inbox2/conversations-table';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type Session from 'embercom/services/session';

export const STORAGE_KEY = 'inbox.conversationsTableSettings';
export const SEARCH_KEY = '_search_';
export const V2_SEARCH_KEY = '_v2_search_';

export interface SelectedColumn {
  id: string;
  width?: number;
}

export interface InboxSettings {
  selectedColumns: SelectedColumn[];
  selectedSortOption: SortState;
  selectedStateOption: InboxStateOption;
  selectedMentionsStatus: InboxMentionsStatus;
}

export default class ConversationsTableSettings extends Service {
  @service declare session: Session;

  getInboxSettings(inbox: Inbox): InboxSettings {
    return (
      this.getSettings()[`${this.inboxKey(inbox)}-${this.session.workspace.id}`] ||
      this.getSettings()[this.inboxKey(inbox)] ||
      this.defaultSettings()
    );
  }

  saveInboxSettings(inbox: Inbox, inboxSetting: InboxSettings) {
    let settings = this.getSettings();

    if (this.session.workspace.isFeatureEnabled('team-help-desk-experience-app-based-settings')) {
      settings[`${this.inboxKey(inbox)}-${this.session.workspace.id}`] = inboxSetting;
    } else {
      settings[this.inboxKey(inbox)] = inboxSetting;
    }
    storage.set(STORAGE_KEY, settings);
  }

  getSearchKey(isNewSearch: boolean) {
    if (isNewSearch) {
      return V2_SEARCH_KEY;
    } else {
      return SEARCH_KEY;
    }
  }

  getSearchSettings(isNewSearch = false): InboxSettings {
    return this.getSettings()[this.getSearchKey(isNewSearch)] || this.defaultSettings(isNewSearch);
  }

  saveSearchSettings(inboxSetting: InboxSettings, isNewSearch = false) {
    let settings = this.getSettings();
    settings[this.getSearchKey(isNewSearch)] = inboxSetting;
    storage.set(STORAGE_KEY, settings);
  }

  getCustomerReportsTableSettings(trackerTicketTypeId: number): InboxSettings {
    let key = this.customerReportsTableKey(trackerTicketTypeId);
    return this.getSettings()[key] || this.defaultSettings();
  }

  saveCustomerReportsTableSettings(trackerTicketTypeId: number, inboxSetting: InboxSettings) {
    let settings = this.getSettings();
    settings[this.customerReportsTableKey(trackerTicketTypeId)] = inboxSetting;
    storage.set(STORAGE_KEY, settings);
  }

  private inboxKey(inbox: Inbox): string {
    return `${inbox.category}-${inbox.id}`;
  }

  private customerReportsTableKey(trackerTicketTypeId: number): string {
    return `customerReportsTable.trackerType.${trackerTicketTypeId}`;
  }

  private getSettings(): { [key: string]: InboxSettings } {
    return storage.get(STORAGE_KEY) || {};
  }

  defaultSettings(isNewSearch = false): InboxSettings {
    return {
      selectedColumns: [
        { id: 'contact' },
        { id: 'relevantPart' },
        { id: 'company' },
        { id: 'title' },
        { id: 'activity' },
        { id: 'description' },
        { id: 'priority' },
        { id: 'waitingSince' },
        { id: 'lastUpdated' },
        { id: 'nextBreachTime' },
        { id: 'state' },
        { id: 'teamAssignee' },
        { id: 'adminAssignee' },
        { id: 'tags' },
      ],
      selectedSortOption: isNewSearch
        ? {
            sortField: 'relevance',
            direction: 'desc',
          }
        : {
            sortField: 'sorting_updated_at',
            direction: 'desc',
          },
      selectedStateOption: InboxStateOption.Open,
      selectedMentionsStatus: InboxMentionsStatus.All,
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    conversationsTableSettings: ConversationsTableSettings;
    'conversations-table-settings': ConversationsTableSettings;
  }
}
