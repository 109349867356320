/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';
import moment from 'moment-timezone';

export default class LogService extends Service {
  @service declare appService: any;
  @service declare session: Session;

  get appId() {
    let app =
      this.appService.app || (this.session.isWorkspaceLoaded ? this.session.workspace : null);
    return app?.id;
  }

  log(message: string | Record<string, unknown>) {
    return ajax({
      type: 'POST',
      url: '/ember/log',
      data: JSON.stringify({
        app_id: this.appId,
        message,
      }),
    });
  }

  logJSON(message: Record<string, unknown>) {
    return ajax({
      type: 'POST',
      url: '/ember/log',
      data: JSON.stringify({
        json: true,
        app_id: this.appId,
        timestamp: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
        message,
      }),
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    logService: LogService;
    'log-service': LogService;
  }
}
