/* RESPONSIBLE TEAM: team-channels */
import Service from '@ember/service';
import { inject as service } from '@ember/service';

const DEFAULT_CHANNEL = 'email';

export default class DraftConversationsService extends Service {
  @service appService;

  constructor() {
    super(...arguments);

    if (!this.latestChannel) {
      if (this.draft?.initial_channel) {
        this.latestChannel = this.draft.initial_channel;
      } else {
        this.latestChannel = DEFAULT_CHANNEL;
      }
    }
  }

  get app() {
    return this.appService.app;
  }

  get draftLocalStorageKey() {
    return `new-conversation-draft-${this.app.id}`;
  }

  get draft() {
    let draftJSON = localStorage.getItem(this.draftLocalStorageKey);
    if (draftJSON) {
      return JSON.parse(draftJSON);
    }
  }

  set draft(draftObject) {
    localStorage.setItem(this.draftLocalStorageKey, JSON.stringify(draftObject));
  }

  removeDraft() {
    localStorage.removeItem(this.draftLocalStorageKey);
  }

  get latestChannelLocalStorageKey() {
    return `new-conversation-latest-channel-${this.app.id}`;
  }

  get latestChannel() {
    return localStorage.getItem(this.latestChannelLocalStorageKey);
  }

  set latestChannel(channel) {
    localStorage.setItem(this.latestChannelLocalStorageKey, channel);
  }
}
