/* RESPONSIBLE TEAM: team-tickets-1 */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default class ConversationAttributeDescriptorSerializer extends EmbercomBaseSerializer.extend(
  EmbeddedRecordsMixin,
) {
  attrs = {
    listOptions: { embedded: 'always' },
    relationship: { embedded: 'always' },
    reference: { embedded: 'always' },
    conditions: { embedded: 'always' },
  };
}
