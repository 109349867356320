/* RESPONSIBLE TEAM: team-ai-chatbot */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';

export default class AiAgentEmailSetupValid extends BaseValidator {
  @service declare aiAgentSetupService: AiAgentSetupService;

  validate() {
    let result = this.aiAgentSetupService.validateEmailSetup;
    return result.isValid || result.message!;
  }
}
