/* RESPONSIBLE TEAM: team-frontend-tech */
import Service, { inject as service } from '@ember/service';

export default class FilterConfigsService extends Service {
  @service intl;
  @service appService;

  availableContinents() {
    return {
      AF: this.intl.t('app.lib.reporting.available-continents.africa'),
      AN: this.intl.t('app.lib.reporting.available-continents.antarctica'),
      AS: this.intl.t('app.lib.reporting.available-continents.asia'),
      EU: this.intl.t('app.lib.reporting.available-continents.europe'),
      NA: this.intl.t('app.lib.reporting.available-continents.north-america'),
      OC: this.intl.t('app.lib.reporting.available-continents.oceania'),
      SA: this.intl.t('app.lib.reporting.available-continents.south-america'),
    };
  }
}
