/* RESPONSIBLE TEAM: team-ai-chatbot */
import { buildValidations, validator } from 'ember-cp-validations';
import { alias } from '@ember/object/computed';

export default buildValidations({
  botMode: [validator('presence', true)],
  botIntroLocalizations: [
    validator('has-many', {
      disabled: alias('model.disableLocalizationsValidation'),
    }),
  ],
  botOnlyMessageLocalizations: [
    validator('has-many', {
      disabled: alias('model.disableBotOnlyMessageLocalizationsValidation'),
      dependentKeys: ['model.botMode'],
    }),
  ],
  genericTriageLocalizations: [
    validator('all-generic-triages-are-valid-or-disabled', {
      disabled: alias('model.disableGenericTriageValidation'),
    }),
  ],
});
