/* RESPONSIBLE TEAM: team-help-desk-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type Session from './session';
import { type SearchByTypeResponse } from './quick-search';
import Tag from 'embercom/objects/inbox/tag';
import SearchableDocument from 'embercom/objects/inbox/searchable-document';
import { EntityType } from 'embercom/models/data/entity-types';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { request } from 'embercom/lib/inbox/requests';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type IntlService from 'embercom/services/intl';
import type FuzzySearch from './fuzzy-search';
import { prepareStringForFuzzySearch } from './fuzzy-search';
import { tracked } from '@glimmer/tracking';
import type CoreData from './inbox2-core-data';
import { trackedFunction } from 'ember-resources/util/function';
import type Tracing from 'embercom/services/tracing';

type TagWireFormat = {
  id: string | number;
  name: string;
};

export type TagResponse = {
  tags: {
    total: number;
    version: number;
    results: TagWireFormat[];
  };
};

export default class Inbox2TagsSearch extends Service {
  @service declare session: Session;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare fuzzySearch: FuzzySearch;
  @service declare inbox2CoreData: CoreData;
  @service declare tracing: Tracing;

  @tracked private allTags: SearchableDocument[] = [];

  get searchableTags(): SearchableDocument[] | null {
    if (this.inbox2CoreData.isEnabled) {
      return this._searchableTags.value;
    } else {
      return this.allTags;
    }
  }

  private _searchableTags = trackedFunction(this, async () => {
    return await this.inbox2CoreData.storeFor('tags').fetchAllSearchable();
  });

  async loadAllTags() {
    try {
      let json = await taskFor(this.fetchTags).perform();
      this.allTags = json.tags.results.map(this.toSearchableDocument);
    } catch {
      this.notificationsService.notifyError(this.intl.t('inbox.tags-search.error-loading-tags'));
    }
  }

  get tagsLoaded(): boolean {
    if (this.inbox2CoreData.isEnabled) {
      return this.inbox2CoreData.isBooted;
    } else {
      let task = taskFor(this.fetchTags);
      return !task.isRunning && task.performCount > 0;
    }
  }

  @task({ keepLatest: true })
  private *fetchTags(): TaskGenerator<TagResponse> {
    return yield this.tracing.inSpan(
      {
        name: 'fetchTags',
        resource: 'inbox2-tags-search',
        attributes: {
          'features.inbox-ignore-imported-tags': this.session.workspace.isFeatureEnabled(
            'inbox-ignore-imported-tags',
          ),
        },
      },
      async () => {
        let response = await request(`/ember/inbox/tags?app_id=${this.session.workspace.id}`);
        let json = (await response.json()) as unknown as TagResponse;
        return json;
      },
    );
  }

  async filterTags(
    searchTerm?: string,
    excludeIds?: string[],
    size?: number,
  ): Promise<SearchByTypeResponse> {
    return await this.tracing.inSpan(
      {
        name: 'filterTags',
        resource: 'inbox2-tags-search',
        attributes: {
          'features.inbox-ignore-imported-tags': this.session.workspace.isFeatureEnabled(
            'inbox-ignore-imported-tags',
          ),
        },
      },
      async () => {
        let allTags: SearchableDocument[];
        if (this.inbox2CoreData.isEnabled) {
          allTags = await this.inbox2CoreData.storeFor('tags').fetchAllSearchable();
        } else {
          allTags = this.allTags;
        }

        if (!searchTerm) {
          let tags = size ? allTags.slice(0, size) : allTags;
          return {
            total: tags.length,
            results: tags,
          };
        }

        if (excludeIds && excludeIds.length) {
          allTags = allTags.filter((tag) => !excludeIds.includes((tag.data as Tag).id));
        }

        let results = this.fuzzySearch.search(searchTerm, allTags, 'data.name', {
          limit: size,
        });

        return {
          total: results.length,
          results,
        };
      },
    );
  }

  filterAndHighlightInitialTags(searchTerm: string, tags: Tag[]): SearchableDocument[] {
    let searchableDocs = tags.map(this.toSearchableDocument);
    if (!searchTerm) {
      return searchableDocs;
    }

    return this.fuzzySearch.search(searchTerm, searchableDocs, 'data.name');
  }

  private toSearchableDocument(tag: Tag): SearchableDocument {
    return new SearchableDocument(
      EntityType.Tag,
      SearchableType.Tag,
      new Date(),
      new Date(),
      new Tag(tag.id, tag.name, tag.createdBy, tag.createdAt),
      undefined,
      undefined,
      prepareStringForFuzzySearch(tag.name),
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    inbox2TagsSearch: Inbox2TagsSearch;
    'inbox2-tags-search': Inbox2TagsSearch;
  }
}
