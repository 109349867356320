"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _WeakIdentityMap_size, _WeakIdentityMap_map, _WeakIdentityMap_registry;
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeakIdentityMap = void 0;
class WeakIdentityMap {
    constructor() {
        _WeakIdentityMap_size.set(this, void 0);
        _WeakIdentityMap_map.set(this, void 0);
        _WeakIdentityMap_registry.set(this, void 0);
        __classPrivateFieldSet(this, _WeakIdentityMap_size, 0, "f");
        __classPrivateFieldSet(this, _WeakIdentityMap_map, new Map(), "f");
        __classPrivateFieldSet(this, _WeakIdentityMap_registry, new FinalizationRegistry((heldValue) => {
            var _a;
            const ref = __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").get(heldValue.key);
            if (ref === heldValue.ref) {
                __classPrivateFieldSet(this, _WeakIdentityMap_size, (_a = __classPrivateFieldGet(this, _WeakIdentityMap_size, "f"), _a--, _a), "f");
                __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").delete(heldValue.key);
            }
        }), "f");
    }
    has(key) {
        return typeof __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").get(key)?.deref() !== 'undefined';
    }
    get(key) {
        return __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").get(key)?.deref();
    }
    set(key, value) {
        var _a, _b;
        const oldValue = __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").get(key)?.deref();
        if (typeof oldValue !== 'undefined') {
            __classPrivateFieldSet(this, _WeakIdentityMap_size, (_a = __classPrivateFieldGet(this, _WeakIdentityMap_size, "f"), _a--, _a), "f");
            __classPrivateFieldGet(this, _WeakIdentityMap_registry, "f").unregister(oldValue);
        }
        const ref = new WeakRef(value);
        __classPrivateFieldSet(this, _WeakIdentityMap_size, (_b = __classPrivateFieldGet(this, _WeakIdentityMap_size, "f"), _b++, _b), "f");
        __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").set(key, ref);
        __classPrivateFieldGet(this, _WeakIdentityMap_registry, "f").register(value, { key, ref });
        return this;
    }
    delete(key) {
        var _a;
        const value = __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").get(key)?.deref();
        if (typeof value !== 'undefined') {
            __classPrivateFieldSet(this, _WeakIdentityMap_size, (_a = __classPrivateFieldGet(this, _WeakIdentityMap_size, "f"), _a--, _a), "f");
            __classPrivateFieldGet(this, _WeakIdentityMap_registry, "f").unregister(value);
        }
        return __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").delete(key);
    }
    keys() {
        return __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").keys();
    }
    clear() {
        for (const ref of __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").values()) {
            const value = ref.deref();
            if (typeof value !== 'undefined') {
                __classPrivateFieldGet(this, _WeakIdentityMap_registry, "f").unregister(value);
            }
        }
        __classPrivateFieldSet(this, _WeakIdentityMap_size, 0, "f");
        __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").clear();
    }
    forEach(cb, thisArg) {
        __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").forEach((ref, key) => {
            const value = ref.deref();
            if (typeof value !== 'undefined') {
                cb.call(thisArg, value, key, this);
            }
        });
    }
    entries() {
        const entries = __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").entries();
        const next = () => {
            for (const entry of entries) {
                const value = entry[1].deref();
                if (typeof value !== 'undefined') {
                    return {
                        done: false,
                        value: [entry[0], value],
                    };
                }
            }
            return {
                done: true,
                value: undefined,
            };
        };
        return {
            [Symbol.iterator]() {
                return this;
            },
            next,
        };
    }
    values() {
        const refs = __classPrivateFieldGet(this, _WeakIdentityMap_map, "f").values();
        const next = () => {
            for (const ref of refs) {
                const value = ref.deref();
                if (typeof value !== 'undefined') {
                    return {
                        done: false,
                        value: value,
                    };
                }
            }
            return {
                done: true,
                value: undefined,
            };
        };
        return {
            [Symbol.iterator]() {
                return this;
            },
            next,
        };
    }
    get size() {
        return __classPrivateFieldGet(this, _WeakIdentityMap_size, "f");
    }
}
exports.WeakIdentityMap = WeakIdentityMap;
_WeakIdentityMap_size = new WeakMap(), _WeakIdentityMap_map = new WeakMap(), _WeakIdentityMap_registry = new WeakMap();
