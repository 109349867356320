/* RESPONSIBLE TEAM: team-purchase-experience */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';
import { type TaskFunction } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { captureException } from 'embercom/lib/sentry';

export default class FinOptInService extends Service {
  @service declare permissionsService: any;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @tracked showFinOptInModal = false;

  declare postAcceptCallback: TaskFunction<void, any>;

  get hasBillingPermissions() {
    let hasBillingPermissions = this.permissionsService.currentAdminCan(
      'can_access_billing_settings',
    );
    if (!hasBillingPermissions) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'fin_opt_in_modal',
        place: 'billing_permissions',
      });
    }
    return hasBillingPermissions;
  }

  get hasOptedInToFin() {
    return this.appService.app.hasOptedInToFin;
  }

  // If the workspace was created prior to the Fin General Release
  // Or is a cardless trial created on or after June 1st 2023 (Wave 3)
  // we will show opt in copy and automatically opt them in on set live of fin
  get bypassFinOptInModal() {
    return (
      this.appService.app.wasCreatedAfterFinGeneralRelease ||
      this.appService.app.wasCreatedAfterCardlessFinTrialRelease
    );
  }

  @action
  openFinOptInModal() {
    if (this.bypassFinOptInModal) {
      taskFor(this.acceptTermsAndConditions).perform();
    } else {
      this.showFinOptInModal = true;
    }
  }

  @action
  closeFinOptInModal() {
    this.showFinOptInModal = false;
  }

  @task
  *postAcceptOptIn() {
    let params = {
      app_id: this.appService.app.id,
    };
    try {
      yield post('/ember/fin_opt_in', params);
      if (this.bypassFinOptInModal) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opted_in',
          object: 'background_opt_in',
        });
      } else {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'opted_in',
          object: 'fin_opt_in_modal',
        });
      }
      this.appService.app.set('hasOptedInToFin', true);
    } catch (error) {
      captureException(new Error('Fin Opt In postAcceptOptIn failed'), {
        fingerprint: ['fin-opt-in-service', 'postAcceptOptIn'],
        extra: {
          bypassFinOptInModal: this.bypassFinOptInModal,
          hasOptedInToFin: this.hasOptedInToFin,
          appId: this.appService.app.id,
        },
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      if (error?.jqXHR?.status !== 409) {
        throw error;
      }
    }
  }

  @task
  *acceptTermsAndConditions() {
    try {
      yield taskFor(this.postAcceptOptIn).perform();
      this.closeFinOptInModal();
      if (!this.bypassFinOptInModal) {
        this.notificationsService.notifyConfirmation(this.intl.t('modal.fin-opt-in.success'));
      }
      yield taskFor(this.postAcceptCallback).perform();
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('modal.fin-opt-in.request_error'));
      captureException(new Error('Fin Opt In acceptTermsAndConditions failed'), {
        fingerprint: ['fin-opt-in-service', 'acceptTermsAndConditions'],
        extra: {
          bypassFinOptInModal: this.bypassFinOptInModal,
          has_opted_in_to_fin: this.hasOptedInToFin,
          app_id: this.appService.app.id,
        },
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    finOptInService: FinOptInService;
    'fin-opt-in-service': FinOptInService;
  }
}
