/* RESPONSIBLE TEAM: team-knowledge-interop */
import type Store from '@ember-data/store';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import { post } from 'embercom/lib/ajax';
import type ArticleContent from 'embercom/models/articles/article-content';
import type InternalArticle from 'embercom/models/content-service/internal-article';
import { EntityType } from 'embercom/models/data/entity-types';
import { type PaywallConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import { tracked } from 'tracked-built-ins';
import { MAX_ARTICLES_IN_A_COLLECTION } from 'embercom/lib/articles/constants';
import type FileSourceContent from 'embercom/models/file-source-content';
import { capitalize } from '@ember/string';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';

const EDIT_ROUTE = 'apps.app.knowledge-hub.edit';
const VIEW_ROUTE = 'apps.app.knowledge-hub.view';

export default class KnowledgeHubEditorService extends Service {
  @service declare router: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare store: Store;

  @tracked private activeContent?: LocalizedKnowledgeContent;
  @tracked isPublishing = false;

  get activeContentModel() {
    return this.activeContent as LocalizedKnowledgeContent & Model;
  }

  get contentId() {
    return this.activeContentModel?.id;
  }

  get activeContentIsInternalArticle() {
    return this.activeContent?.entityType === EntityType.InternalArticle;
  }

  get activeContentIsFileSourceContent() {
    return this.activeContent?.entityType === EntityType.FileSourceContent;
  }

  get activeContentHasNoParent() {
    return !!this.activeContent?.parentContent.isRoot;
  }

  get app() {
    return this.appService.app;
  }

  registerActiveContent(activeContent: LocalizedKnowledgeContent | undefined) {
    this.activeContent = activeContent;
  }

  editActiveContent() {
    this.trackAnalyticsEvent('clicked_edit');
    this.router.transitionTo(EDIT_ROUTE, this.activeContent?.entityName, this.contentId);
  }

  cancelEditActiveContent() {
    if (this.activeContent) {
      if (this.activeContentIsInternalArticle) {
        let internalArticle = this.activeContentModel as InternalArticle;
        internalArticle.currentVersion?.rollbackAttributes();
      }

      this.activeContentModel.rollbackAttributes();

      this.trackAnalyticsEvent('cancelled_edit');

      this.router.transitionTo(VIEW_ROUTE, this.activeContent.entityName, this.contentId);
    }
  }

  async deleteActiveContent() {
    try {
      let otherAvailableTranslations = this.routeToOtherTranslation;
      // TODO Rewrite this to be generic rather than specific to file source content
      if (this.activeContentIsFileSourceContent) {
        let fileSourceContent = this.activeContent as unknown as FileSourceContent;
        await fileSourceContent.delete();
      } else {
        await this.activeContentModel.destroyRecord();
      }
      this.notificationsService.notifyConfirmation(
        capitalize(
          this.intl.t('knowledge-hub.content-editor.delete.success-message', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        ),
      );
      this.trackAnalyticsEvent('deleted');

      if (otherAvailableTranslations) {
        this.router.transitionTo(
          otherAvailableTranslations.route,
          ...otherAvailableTranslations.model,
        );
        return;
      }
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.delete.error-message'),
      );
    }
  }

  get routeToOtherTranslation() {
    if (this.activeContent?.entityType === EntityType.ArticleContent) {
      // TODO Re-write this to be generic rather than specific to articles
      let article = (this.activeContentModel as unknown as ArticleContent).article;
      let otherAvailableTranslations = article.orderedArticleContents.filter(
        (articleContent) => articleContent && articleContent.id !== this.activeContentModel.id,
      );

      // Redirect to the first available translation if the current content is an article
      if (otherAvailableTranslations.length !== 0) {
        return {
          route: 'apps.app.knowledge-hub.view',
          model: ['article', otherAvailableTranslations[0]!.id],
        };
      }
    }
    return null;
  }

  async saveActiveContent() {
    if (this.activeContent) {
      try {
        await this.activeContentModel.save();
        this.trackAnalyticsEvent('edited');
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.content-editor.edit.save-success-confirmation'),
        );
        this.router.transitionTo(VIEW_ROUTE, this.activeContent.entityName, this.contentId);
      } catch (e) {
        this.notificationsService.notifyResponseError(e, {
          default: this.intl.t('knowledge-hub.content-editor.edit.save-error-message'),
        });
      }
    }
  }

  async reloadActiveContent() {
    await this.activeContentModel.reload();
  }

  async updateHelpCenterAndCollection(article: any) {
    try {
      if (this.app.canUseMultipleHelpCenters) {
        await article.get('updateSettingsMultiple').perform();
      } else {
        await article.get('updateSettings').perform();
      }
      await article.reload();
    } catch (error) {
      if (error.jqXHR?.responseJSON?.errors?.key === 'max_collection_articles_reached') {
        this.notificationsService.notifyError(
          this.intl.t('articles.collections.add_article.max_articles_reached', {
            limit: MAX_ARTICLES_IN_A_COLLECTION,
          }),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('articles.editor.side-drawer.settings.problem-in-saving-settings'),
        );
      }
    }
  }

  async publishActiveContent() {
    if (this.activeContent) {
      this.isPublishing = true;
      try {
        await this.activeContentModel.publishContent();
        this.trackAnalyticsEvent('published');
        this.activeContentModel.reload();
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.content-editor.edit.publish-message.success', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        );
        this.router.transitionTo(VIEW_ROUTE, this.activeContent.entityName, this.contentId);
      } catch (e) {
        this.notificationsService.notifyResponseError(e, {
          default: this.intl.t('knowledge-hub.content-editor.edit.publish-message.error', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        });
      } finally {
        this.isPublishing = false;
      }
    }
  }

  async unpublishActiveContent() {
    if (this.activeContent) {
      try {
        await this.activeContentModel.unpublishContent();
        this.trackAnalyticsEvent('unpublished');
        this.activeContentModel.reload();
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.content-editor.edit.unpublish-message.success', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        );
        this.router.transitionTo(VIEW_ROUTE, this.activeContent.entityName, this.contentId);
      } catch (e) {
        this.notificationsService.notifyResponseError(e, {
          default: this.intl.t('knowledge-hub.content-editor.edit.unpublish-message.error', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        });
      }
    }
  }

  trackAnalyticsEvent(action: string, place = 'knowledge_hub_content_editor') {
    if (this.activeContent) {
      this.intercomEventService.trackAnalyticsEvent({
        action,
        object: this.activeContent.entityName,
        place,
        contentId: this.activeContentModel.id,
      });
    }
  }

  @restartableTask
  *changeInternalArticleVisibility(): TaskGenerator<void> {
    let internalArticle = this.activeContentModel as InternalArticle;
    try {
      let urlSuffix = internalArticle.isLive ? 'unpublish' : 'publish';
      let response = yield post(
        `/ember/content_service/internal_articles/${internalArticle.id}/${urlSuffix}`,
        {
          app_id: this.appService.app.id,
          id: internalArticle.id,
          version_id: internalArticle.currentVersion!.id,
        },
      );

      let successMessage = internalArticle.isLive
        ? this.intl.t('knowledge-hub.content-editor.internal-note.visibility-section.unpublished')
        : this.intl.t('knowledge-hub.content-editor.internal-note.visibility-section.published');

      this.notificationsService.notifyConfirmation(successMessage);
      this.trackAnalyticsEvent(`${urlSuffix}_internal_article`);
      this.store.pushPayload({ 'content-service/internal-article': response });
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.internal-note.visibility-section.error'),
      );
    }
  }

  async autoSaveContent() {
    await this.activeContentModel.save();
  }

  get paywallConfig() {
    let configs = this.activeContentModel?.editorConfig?.paywallConfig;
    return configs ? this.getPaywallConfigToShow(configs) : undefined;
  }

  private getPaywallConfigToShow(paywalls: PaywallConfig[], index = 0): PaywallConfig | undefined {
    if (index >= paywalls.length) {
      return undefined;
    }
    if (!this.appService.app.canUseFeature(paywalls[index].featureKey)) {
      return paywalls[index];
    }
    return this.getPaywallConfigToShow(paywalls, index + 1);
  }
}

declare module '@ember/service' {
  interface Registry {
    knowledgeHubEditorService: KnowledgeHubEditorService;
    'knowledge-hub-editor-service': KnowledgeHubEditorService;
  }
}
