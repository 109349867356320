/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';

export default class MouseTrackingService extends Service {
  @service intercomEventService;
  @service userIdleService;
  @service activityTrackingSamplingService;

  constructor(...args) {
    super(...args);
    this.trackers = new Set();
    this.timers = new Map();
    this.userIdleService.on('idle', () => {
      this._pauseTrackers();
    });
    this.userIdleService.on('active', () => {
      this._resumeTrackers();
    });
  }

  registerTracker(tracker) {
    this.trackers.add(tracker);
  }

  unregisterTracker(tracker) {
    this.accumulateTime(tracker);
    this.trackers.delete(tracker);
  }

  _pauseTrackers() {
    for (let tracker of this.trackers) {
      tracker.pause();
    }
  }

  _resumeTrackers() {
    for (let tracker of this.trackers) {
      tracker.resume();
    }
  }

  accumulateTime(tracker) {
    let key = tracker.accumulatorKey;
    let currentAccumulatedTime = this.timers.has(key) ? this.timers.get(key) : 0;
    this.timers.set(key, currentAccumulatedTime + tracker.getAndResetAccumulatedTime());
  }

  report() {
    for (let tracker of this.trackers) {
      this.accumulateTime(tracker);
    }
    let data = {};
    for (let key of this.timers.keys()) {
      let time = this.timers.get(key);
      if (time !== 0) {
        data[key] = time;
        this.timers.set(key, 0);
      }
    }
    if (Object.keys(data).length !== 0) {
      if (this.activityTrackingSamplingService.shouldSampleActivity) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'mousetime',
          object: 'application',
          section: 'global',
          place: 'global',
          timings: JSON.stringify(data),
        });
      }
    }
  }
}
