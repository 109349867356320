/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { adminHasSeen, markViewAsSeen } from 'embercom/lib/admin-view-tracking';

export default class BannerDismissalService extends Service {
  @service store;

  @action
  async dismiss(key) {
    return await markViewAsSeen(this.store, key);
  }

  @action
  async hasDismissed(key) {
    return await adminHasSeen(this.store, key);
  }
}
