/* RESPONSIBLE TEAM: team-phone */

import Service, { inject as service } from '@ember/service';
import { get, post } from 'embercom/lib/ajax';
import { formatSeconds } from 'embercom/lib/duration-formatter';
import type AdminAwayService from 'embercom/services/admin-away-service';
import { AWAY_REASONS } from 'embercom/services/admin-away-service';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

export default class IntercomCallService extends Service {
  @tracked conversationId: number | null = null;
  @tracked workspacePhoneNumbers: PhoneNumber[] = [];
  @tracked isDiallerOpen = false;
  @tracked toPhoneNumber?: string;
  @tracked isWorkspacePhoneNumbersLoaded = false;
  @tracked returnToCallAction?: () => void;
  @tracked transferCallInternallyTask?: (assigneeId: number) => TaskGenerator<void>;
  @tracked transferCallExternallyTask?: (phoneNumber: string) => TaskGenerator<void>;
  @tracked listeningCallId?: string;

  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare adminAwayService: AdminAwayService;
  @service declare session: Session;
  @service declare store: Store;
  @service intercomEventService: any;

  async startEscalationCall(conversationId: number, externalCallId: string | undefined) {
    this.conversationId = conversationId;

    let appId = this.appService.app.id;
    let adminId = this.appService.app.currentAdmin.id;

    await post('/ember/phone_call/start_escalation', {
      app_id: appId,
      admin_id: adminId,
      conversation_id: conversationId,
      external_call_id: externalCallId,
    });
  }

  async endCall(conversationId: number, duration?: number | null) {
    let metadata = {
      callType: 'outbound',
      duration: duration ? formatSeconds(duration) : null,
    };

    let appId = this.appService.app.id;
    let adminId = this.appService.app.currentAdmin.id;

    await post('/ember/phone_call/end', {
      app_id: appId,
      admin_id: adminId,
      conversation_id: conversationId,
      metadata,
    });

    await this.setEndCallAdminState();
  }

  async stopRecording(conversationId: number) {
    let appId = this.appService.app.id;

    await post('/ember/phone_call/stop_recording', {
      app_id: appId,
      conversation_id: conversationId,
    });
  }

  async startRecording(conversationId: number) {
    let appId = this.appService.app.id;

    await post('/ember/phone_call/start_recording', {
      app_id: appId,
      conversation_id: conversationId,
    });
  }

  async startListeningToCallInProgess(callId: string) {
    this.listeningCallId = callId;

    await post('/ember/phone_call/listen', {
      app_id: this.appService.app.id,
      call_id: callId,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }

  async stopListeningToCallInProgress(callId: string) {
    this.adminAwayService.setAdminAsAvailable();
    this.listeningCallId = undefined;

    await post('/ember/phone_call/listen_stop', {
      app_id: this.appService.app.id,
      call_id: callId,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }

  async setEndCallAdminState() {
    let callWrapUpSettings = await this.callWrapUpEnabled();
    if (callWrapUpSettings.wrapUpTimeEnabled) {
      await this.adminAwayService.forceAdminConsistency(callWrapUpSettings.wrapUpTimeAmount);
    } else {
      this.adminAwayService.setAdminAsAvailable();
    }
  }

  async setAdminOnCall() {
    if (!this.session.isWorkspaceLoaded) {
      this.session.switchWorkspace(this.appService.app.id);
    }

    if (!this.session.isTeammateLoaded) {
      await this.session.getTeammate(this.appService.app.id);
    }

    this.adminAwayService.setAdminAsAway(AWAY_REASONS[4]);
  }

  async setAdminAsCoaching() {
    if (!this.session.isWorkspaceLoaded) {
      this.session.switchWorkspace(this.appService.app.id);
    }

    if (!this.session.isTeammateLoaded) {
      await this.session.getTeammate(this.appService.app.id);
    }

    this.adminAwayService.setAdminAsAway(AWAY_REASONS[0]);
  }

  private async callWrapUpEnabled() {
    let callingSettings = await this.store.queryRecord('settings/calling', {});
    return {
      wrapUpTimeEnabled: callingSettings?.wrapUpTimeEnabled,
      wrapUpTimeUnit: callingSettings?.wrapUpTimeUnit,
      wrapUpTimeAmount: callingSettings?.wrapUpTimeAmount,
    };
  }

  async checkCallingSupport() {
    try {
      let result = await get('/ember/calling_settings/check_pricing_plan', {
        app_id: this.appService.app.id,
      });

      return result.supported;
    } catch (err) {
      return false;
    }
  }

  async getWorkspacePhoneNumbers() {
    this.workspacePhoneNumbers = (await this.store.findAll('inbox/phone-number')).filter(
      (number: PhoneNumber) => number.isActive,
    );
    this.isWorkspacePhoneNumbersLoaded = true;
  }

  openDialler(
    toPhoneNumber?: string,
    returnToCallAction?: () => void,
    transferCallInternallyTask?: (assigneeId: number) => TaskGenerator<void>,
    transferCallExternallyTask?: (phoneNumber: string) => TaskGenerator<void>,
  ) {
    this.toPhoneNumber = toPhoneNumber;
    this.isDiallerOpen = true;
    this.returnToCallAction = returnToCallAction;
    this.transferCallInternallyTask = transferCallInternallyTask;
    this.transferCallExternallyTask = transferCallExternallyTask;
  }

  closeDialler(skipReturnAction = false) {
    this.toPhoneNumber = undefined;
    this.isDiallerOpen = false;
    if (this.returnToCallAction && !skipReturnAction) {
      this.returnToCallAction();
    }
    this.returnToCallAction = undefined;
    this.transferCallInternallyTask = undefined;
    this.transferCallExternallyTask = undefined;
  }

  transferCallInternally(assigneeId: number) {
    if (this.transferCallInternallyTask) {
      taskFor(this.transferCallInternallyTask).perform(assigneeId);
    }
  }

  transferCallExternally(phoneNumber: string) {
    if (this.transferCallExternallyTask) {
      taskFor(this.transferCallExternallyTask).perform(phoneNumber);
    }
  }

  async findOrCreateContact(phoneNumber: string) {
    let result = await get('/ember/inbox/phone_numbers/find_or_create_contact', {
      app_id: this.appService.app.id,
      phone_number: phoneNumber,
    });

    return result;
  }

  async contactsActiveCallConversation(contactId: number) {
    let result = await get('/ember/phone_call/contact_active_call', {
      app_id: this.appService.app.id,
      contact_id: contactId,
    });

    let conversationId = result.conversation_id;
    return conversationId;
  }

  async getValidatedPhoneNumber(phoneNumber: string, countryCode: string) {
    let response = await get('/ember/inbox/phone_numbers/validate_phone_number', {
      app_id: this.appService.app.id,
      phone_number: phoneNumber,
      country_code: countryCode.toUpperCase(),
    });
    if (response.success) {
      return response.e164;
    } else {
      return null;
    }
  }

  get hasActiveOutboundPhoneNumber() {
    return this.workspacePhoneNumbers.length > 0;
  }
}

declare module '@ember/service' {
  interface Registry {
    intercomCallService: IntercomCallService;
    'intercom-call-service': IntercomCallService;
  }
}
