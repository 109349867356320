/* RESPONSIBLE TEAM: team-phone */
import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const prefixPattern = /^(00)|(\+)/i;
const validCharPattern = /^(\d|\s|\+|\.|\(|\)|\-)*$/;
const nonDigitPattern = /\D/;
const minLengthWithoutPrefix = 9;
const maxLengthWithoutPrefix = 15;

const PhoneNumberFormat = BaseValidator.extend({
  intl: service(),

  validate(value, _options, _model) {
    // validate prefix
    if (!value.match(prefixPattern)) {
      return {
        isValid: false,
        message: this.intl.t('validators.phone-number-format.invalid-prefix'),
      };
    }

    // validate characters
    if (!value.match(validCharPattern)) {
      return {
        isValid: false,
        message: this.intl.t('validators.phone-number-format.invalid-characters'),
      };
    }

    // validate length
    let phoneDigitsWithoutPrefix = value.replace(prefixPattern, '').replace(nonDigitPattern, '');
    if (phoneDigitsWithoutPrefix.length < minLengthWithoutPrefix) {
      return {
        isValid: false,
        message: this.intl.t('validators.phone-number-format.too-short'),
      };
    }
    if (phoneDigitsWithoutPrefix.length > maxLengthWithoutPrefix) {
      return {
        isValid: false,
        message: this.intl.t('validators.phone-number-format.too-long'),
      };
    }

    return true;
  },
});

export default PhoneNumberFormat;
