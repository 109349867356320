/* RESPONSIBLE TEAM: team-frontend-tech */
import Service, { inject as service } from '@ember/service';
import { runTask } from 'ember-lifeline';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NavbarCollapsingService extends Service {
  @service appService;
  @service intercomEventService;
  @service router;
  @service greatGuidanceService;

  @tracked collapsed = false;

  _collapsedSubmenus = null;
  _activeSubmenu = null;

  constructor() {
    super(...arguments);
    this._collapsedSubmenus = [];
  }

  get app() {
    return this.appService.app;
  }

  @action
  toggleNavbarCollapsed() {
    let activeSubmenu = this._activeSubmenu;
    if (this.collapsed) {
      this._collapsedSubmenus.removeObject(activeSubmenu);
      this._toggleCollapsed();
      this._trackToggleSubmenuAnalyticsEvent('expanded');
    } else {
      this._collapsedSubmenus.addObject(activeSubmenu);
      this._toggleCollapsed();
      this._trackToggleSubmenuAnalyticsEvent('collapsed');
      this.greatGuidanceService.isFloatingWidgetExpanded = false;
    }
  }

  setCollapsedSubmenu(submenuTitle) {
    this._collapsedSubmenus.addObject(submenuTitle);
  }

  removeCollapsedSubmenu(submenuTitle) {
    this._collapsedSubmenus.removeObject(submenuTitle);
  }

  setActiveSubmenu(submenuTitle) {
    this._activeSubmenu = submenuTitle;
    this._updateCollapsedState();
  }

  _updateCollapsedState() {
    let activeSubmenu = this._activeSubmenu;
    let activeSubmenuIsCollapsed = this._collapsedSubmenus.includes(activeSubmenu);
    this.collapsed = activeSubmenuIsCollapsed;
  }

  _toggleCollapsed() {
    this.collapsed = !this.collapsed;
    runTask(
      this,
      () => {
        window.dispatchEvent(new Event('resize'));
      },
      200,
    );
  }

  _trackToggleSubmenuAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      place: this.get('router.currentRouteName'),
      object: 'submenu',
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    });
  }
}
